import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Popconfirm, Row, Col,Table,Select,Tag,Radio,Button,DatePicker, Modal, Input} from "antd";
import './App.css';
import AddSystem from './AddSystem'
import moment from 'moment';
import {ApiHeader,strings,apiurl} from './data';
const Option = Select.Option;

const locale = 'EN';

class System extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            systemData:null,
            resource:null,            
            loading:false,
            triggerReload:false,
            showalertmodel:false,
            alertmessage:"",
            contacts:null,
            originalContacts:null,
            admin1err:false,
            tech1err:false,
            mechanized:'V',
            originalMechanized:'V',
            activeCode:''
        }
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber',19);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        //console.log(sd);
        this.setState({systemData:sd,activeCode:sd.active_wshroom_code});
        this.getcontacts();
    }
  
    componentWillUnmount() {
  
    }

    clearScrren=()=>{
        this.setState({
            contacts:this.state.originalContacts,
            admin1err:false,
            tech1err:false,
        })        
    }

    savecontacts=()=>{ 
        this.setState({ admin1err:false, tech1err:false});     
        var rdata = this.state.contacts; 
        var isvalid = true;
        if(!rdata.admin1 || rdata.admin1 === ''){
            isvalid = false;
            this.setState({admin1err:true})
        }
        if(!rdata.tech1 || rdata.tech1 === ''){
            isvalid = false;
            this.setState({tech1err:true})
        }
        if(isvalid === false){
            return;
        }
        fetch(apiurl+'system/savesystemcontact', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin', 
            body: JSON.stringify(rdata),         
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            if(data.code == "1"){
                this.setState({ alertmessage: strings.submittedsuccess, showalertmodel: true});
            }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
            this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    savemechanized=(type)=>{ 
        this.setState({showconfirmmodel:false}) 
        var rdata = {mechanized:this.state.mechanized, isSwitch:type}; 
        
        fetch(apiurl+'system/savemechanized', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin', 
            body: JSON.stringify(rdata),         
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            if(data.code == "1"){
                this.setState({ alertmessage: strings.submittedsuccess, showalertmodel: true, originalMechanized:this.state.mechanized});
                this.fetchSystemData()
            }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
            this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    getcontacts=()=>{        
        fetch(apiurl+'system/systemcontact', {
            headers: ApiHeader,
            method: 'GET', 
            credentials: 'same-origin',         
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            if(data.code == "1"){
                this.setState({contacts:data.data, originalContacts:data.data, mechanized:data.mechanized?data.mechanized:'V', originalMechanized:data.mechanized?data.mechanized:'V'});
            }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
            this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    handleChange=(e,type)=>{
        if(type === 1){
            this.setState({contacts:{...this.state.contacts, admin1:e.target.value}})
        }else if(type === 2){
            this.setState({contacts:{...this.state.contacts, admin2:e.target.value}})
        }else if(type === 3){
            this.setState({contacts:{...this.state.contacts, tech1:e.target.value}})
        }else if(type === 4){
            this.setState({contacts:{...this.state.contacts, tech2:e.target.value}})
        }
    }

    fetchSystemData = () => {
        this.setState({ loading: true })
        fetch(apiurl + 'system/list', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                sessionStorage.setItem('systemdata', JSON.stringify(data.data));
                this.setState({systemData:data.data, activeCode:data.data.active_wshroom_code})
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => { this.setState({ loading: false }); console.warn(e); })
    }
    
    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
    }

    saveActiveCode=()=>{
        this.setState({showconfirmmodel:false}) 
        var rdata = {code:this.state.activeCode};         
        fetch(apiurl+'system/saveactivecode', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin', 
            body: JSON.stringify(rdata),         
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            if(data.code == "1"){
                this.setState({ alertmessage: strings.submittedsuccess, showalertmodel: true, originalMechanized:this.state.mechanized});
                this.fetchSystemData()
            }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
            this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    render() {
        return (
            <div className='Tab2Div'>  
                <div style={{padding:10}}>
                    <Row>
                        {this.state.contacts && <Col span={11} className="FirstDiv" style={{height:350,width:'49.5%'}}>
                            <Row xs={24} style={{marginTop:10}}>
                                <Col md={16}>
                                    <div>
                                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                        <span className={this.state.admin1err ? "DateTextErr" : "DateText"} >{strings.adminname1}</span>
                                    </div>
                                    <Input style={{width:'90%'}} value={this.state.contacts.admin1} onChange={(e)=>this.handleChange(e,1)}/>                            
                                </Col>
                            </Row>
                            <Row xs={24} style={{marginTop:10}}>
                                <Col md={16}>
                                    <div>
                                        <span style={{ marginLeft: 12 }} className="DateText">{strings.adminname2}</span>
                                    </div>
                                    <Input style={{width:'90%'}} value={this.state.contacts.admin2} onChange={(e)=>this.handleChange(e,2)}/>                            
                                </Col>
                            </Row>
                            <Row xs={24} style={{marginTop:10}}>
                                <Col md={16}>
                                    <div>
                                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                        <span className={this.state.tech1err ? "DateTextErr" : "DateText"} >{strings.techname1}</span>
                                    </div>
                                    <Input style={{width:'90%'}} value={this.state.contacts.tech1} onChange={(e)=>this.handleChange(e,3)}/>                            
                                </Col>
                            </Row> 
                            <Row xs={24} style={{marginTop:10}}>
                                <Col md={16}>
                                    <div>
                                        <span style={{ marginLeft: 12 }} className="DateText">{strings.techname2}</span>
                                    </div>
                                    <Input style={{width:'90%'}} value={this.state.contacts.tech2} onChange={(e)=>this.handleChange(e,4)}/>                            
                                </Col>
                            </Row> 
                            <Button type="primary" size='large' style={{marginRight: 17,marginTop:20}}  loading={this.state.loading} onClick={this.savecontacts}>
                                {strings.submit}
                            </Button>
                            <Button size='large' style={{marginRight: 20}}  onClick={this.clearScrren}>
                                {strings.clear}
                            </Button>
                        </Col>}
                        <Col span={11} className="FirstDiv" style={{height:350,width:'49.5%',marginLeft:'1%'}}>
                            <Row xs={24} style={{marginTop:10}}>
                                <Col md={10}>
                                    <div>
                                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                        <span className={"DateText"} >{strings.mechanized}</span>
                                    </div>
                                    <Select style={{width:'90%'}} value={this.state.mechanized} onChange={(e)=>this.setState({mechanized:e})}>
                                        <Option value={'V'}>{strings.visitorcarpark}</Option>
                                        <Option value={'O'}>{strings.operationalcarpark}</Option>
                                    </Select>                            
                                </Col>
                                <Col span={14} style={{marginTop:25}}><span className='LongText2' style={{marginLeft:0}}>{strings.adminfunctionformechanized}</span></Col>
                            </Row>
                            <Button type="primary" size='large' style={{marginRight: 17,marginTop:20}} disabled={this.state.mechanized === this.state.originalMechanized} loading={this.state.loading} onClick={()=>this.setState({showconfirmmodel:true})}>
                                {strings.submit}
                            </Button>
                            <Button size='large' style={{marginRight: 20}}  onClick={()=>this.setState({mechanized:this.state.originalMechanized})}>
                                {strings.clear}
                            </Button>
                            <Row xs={24} style={{marginTop:10}}>
                                <Col md={10}>
                                    <div>
                                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                        <span className={"DateText"} >{strings.activewashroomcode}</span>
                                    </div>
                                    <Select style={{width:'90%'}} value={this.state.activeCode} onChange={(e)=>this.setState({activeCode:e})}>
                                        {this.state.systemData && this.state.systemData !== null && this.state.systemData.washroom_codes.split(',').map((c,ci)=><Option key={ci} value={c}>{c}</Option>)}
                                    </Select>                            
                                </Col>
                                <Col span={14} style={{marginTop:35}}><span className='LongText2' style={{marginLeft:0}}>{strings.washroomactivecodemsg}</span></Col>
                            </Row>
                            <Button type="primary" size='large' style={{marginRight: 17,marginTop:20}} loading={this.state.loading} onClick={()=>this.saveActiveCode()}>
                                {strings.submit}
                            </Button>
                            <Button size='large' style={{marginRight: 20}}  onClick={()=>this.setState({activeCode:this.state.systemData.active_wshroom_code})}>
                                {strings.clear}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggermybooking)this.props.gotomybooking(); }}>
                        {strings.ok}
                        </Button>,
                    ]}
                    >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
                <Modal
                    title={strings.alert}
                    visible={this.state.showconfirmmodel}
                    onCancel={() => this.setState({ showconfirmmodel: false })}
                    width={630}
                    footer={[]}
                    >
                        <div>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Button type="primary" size='large' style={{marginRight: 10,marginTop:20}}  loading={this.state.loading} onClick={()=>this.savemechanized(1)}>
                                        {strings.confirmwithswitch}
                                    </Button>
                                    <Button type="primary" size='large' style={{marginRight: 10,marginTop:20}}  loading={this.state.loading} onClick={()=>this.savemechanized(0)}>
                                        {strings.confirmnotswitch}
                                    </Button>
                                    <Button size='large' onClick={()=>this.setState({showconfirmmodel:false})}>
                                        {strings.cancel}
                                    </Button>
                                </Col>
                                <Col></Col>
                            </Row>
                        </div>
                </Modal>
            </div>
        );
    }
}    
export default System;

