import React, { Component, PureComponent} from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col, Table, Select, Tag, Button, Radio, Modal, Popconfirm, Input, DatePicker, message, Empty, Spin } from "antd";
import './App.css';
import moment from 'moment';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES, TYPES, SIZES, ZONES, DIVISIONS } from './data';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Label, ReferenceLine } from 'recharts';
import { Tooltip } from 'recharts';
import { Legend } from 'recharts';
import * as htmlToImage from 'html-to-image';

const Option = Select.Option;
const locale = 'EN';
const COLORS = ['#8884d8', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#83a6ed', '#FF00FF'];

class CustomizedLabel extends PureComponent {    
    render() {
      const { viewBox, title } = this.props;
      return (
        <text x={viewBox.width} y={viewBox.y} dy={-4} fill={'#00FF00'} fontSize={14} textAnchor="middle">
          {title}
        </text>
      );
    }
}

const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value, onPointClick, index, color } = props;   
    return (
        <svg x={cx - 8} y={cy - 8} width={16} height={16} fill="white" onClick={()=>onPointClick({value, index, payload})}>
            <g transform="translate(8 8)">
                <circle r="8" fill={color} />
            </g>
        </svg>
    );
};

class AnalystReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            resource: null,
            loading: false,
            radiooption: 'a',
            showalertmodel: false,
            alertmessage: "",
            floors: [],
            floorIds: [],
            fromdate1: moment().subtract(7, 'days'),
            todate1: moment().subtract(1, 'days'),
            //fromdate1:moment(1651334400000),
            //todate1:moment(1651852800000),
            divisions: ['all'],
            types: ['all'],
            classes: ['all'],
            zones: ['all'],
            sharing: ['all'],
            floor3f: {},
            graphdata: [],
            minval:0,
            dates: [],
            type: 1,
            isGraphType:true,
            detailsStrs:[],
            showDetailsModal:false,
        }
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber', 5);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, systemData: sd });
        var buildingFloor = sd.buildingFloor;
        var floors = [], floor3f = {};
        for (var i = 0; i < buildingFloor.length; i++) {
            if (buildingFloor[i].floorEN === '3/F' || buildingFloor[i].floorEN === '4/F' || buildingFloor[i].floorEN === '5/F' ||
                buildingFloor[i].floorEN === 'G/F' || buildingFloor[i].floorEN === '1/F' || buildingFloor[i].floorEN === '2/F') {
                if (locale === 'EN') {
                    floors.push({ id: buildingFloor[i].id, name: buildingFloor[i].floorEN, name1: buildingFloor[i].floorEN });
                } else {
                    floors.push({ id: buildingFloor[i].id, name: buildingFloor[i].floorZH, name1: buildingFloor[i].floorEN });
                }
            }

            if (buildingFloor[i].floorEN === '3/F') {
                floor3f = buildingFloor[i];
            }
        }
        this.setState({ floors: floors, floor3f: floor3f, floorIds: [floor3f.id] });
    }

    exportExcel = async() => {
        var rdata = {};
        rdata.type = this.state.type;
        rdata.dates = this.state.dates;
        rdata.graphData = this.state.graphdata;
        var graph1 = await htmlToImage.toPng(
            document.getElementById('analystgraphdiv'),
            { quality: 0.6 },
        );
        //console.log(graph1)
        rdata.imageData = graph1.replace('data:image/png;base64,','');
        this.setState({ loading: true })
        fetch(apiurl + 'resource/generateGraphExcel', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                var filename = data.filename;
                var link = document.createElement('a');
                link.href = "/srs_report/"+filename;
                link.download = filename;
                link.click();
                document.removeChild(link);
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    componentWillUnmount() {

    }

    searchData = () => {
        var rdata = {};

        if (this.state.floorIds.length !== 0) {
            rdata.floorIds = this.state.floorIds;
        } else {
            message.warning(strings.plsfillalletc);
            return;
        }

        if (this.state.divisions.length === 0 || this.state.zones.length === 0 || this.state.types.length === 0 || this.state.classes.length === 0 || this.state.sharing.length === 0) {
            message.warning(strings.plsfillalletc);
            return;
        } else {
            rdata.divisions = this.state.divisions;
            rdata.zones = this.state.zones;
            rdata.types = this.state.types;
            rdata.classes = this.state.classes;
            rdata.sharing = this.state.sharing;
        }

        if (this.state.fromdate1 !== null && this.state.todate1 !== null) {
            if (this.state.todate1.diff(this.state.fromdate1, 'months', true) > 6) {
                message.warning(strings.daterangemonthserr);
                return;
            } else if (this.state.todate1.valueOf() < this.state.fromdate1.valueOf()) {
                message.warning(strings.fromtoerr);
                return;
            } else {
                rdata.fromdate = this.state.fromdate1.valueOf();
                rdata.todate = this.state.todate1.valueOf();
            }
        } else {
            message.warning(strings.plsfillalletc);
            return;
        }
        rdata.graphType = this.state.type;

        this.setState({ loading: true, graphdata:[], minval:0})
        fetch(apiurl + 'resource/getGraphData', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var data1 = data.data;
                    this.setState({ graphdata: data1.graphData, dates: data1.dates, minval:data1.minval})
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    searchGraphPointData = (selectedData) => {
        var rdata = {};
        //console.log(selectedData)
        if (this.state.floorIds.length !== 0) {
            rdata.floorIds = this.state.floorIds;
        } else {
            message.warning(strings.plsfillalletc);
            return;
        }

        if (this.state.divisions.length === 0 || this.state.zones.length === 0 || this.state.types.length === 0 || this.state.classes.length === 0 || this.state.sharing.length === 0) {
            message.warning(strings.plsfillalletc);
            return;
        } else {
            rdata.divisions = this.state.divisions;
            rdata.zones = this.state.zones;
            rdata.types = this.state.types;
            rdata.classes = this.state.classes;
            rdata.sharing = this.state.sharing;
        }

        if (this.state.fromdate1 !== null && this.state.todate1 !== null) {
            if (this.state.todate1.diff(this.state.fromdate1, 'months', true) > 6) {
                message.warning(strings.daterangemonthserr);
                return;
            } else if (this.state.todate1.valueOf() < this.state.fromdate1.valueOf()) {
                message.warning(strings.fromtoerr);
                return;
            } else {
                rdata.fromdate = this.state.fromdate1.valueOf();
                rdata.todate = this.state.todate1.valueOf();
            }
        } else {
            message.warning(strings.plsfillalletc);
            return;
        }
        rdata.graphType = this.state.type;
        rdata.time = this.state.type === 1 ? selectedData.payload.date : selectedData.payload.time;

        this.setState({ loading: true,showDetailsModal:true,detailsStrs:[]})
        fetch(apiurl + 'resource/getGraphPointDetails', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var data1 = data.data;
                    this.setState({detailsStrs:data1.details})
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }


    onfromChange = (val) => {
        if (this.state.todate1.diff(val, 'day', true) >= 7) {
            this.setState({ type: 2, fromdate1: val, isGraphType:false, graphdata: [], dates: [], minval:0 })
        }else{
            this.setState({ fromdate1: val, isGraphType:true })
        }
    }

    ontoChange = (val) => {
        if (val.diff(this.state.fromdate1, 'day', true) >= 7) {
            this.setState({ type: 2, todate1: val, isGraphType:false, graphdata: [], dates: [], minval:0 })
        }else{
            this.setState({ todate1: val, isGraphType:true })
        }
    }

    onChangeGtype= (val) => {
        this.setState({ type: val, graphdata: [], dates: [], minval:0 })
    }

    clearScrren = () => {
        this.setState({
            floorId: [this.state.floor3f.id],
            fromdate1: moment().subtract(7, 'days'),
            todate1: moment().subtract(1, 'days'),
            fromdate: '',
            todate: '',
            divisions: ['all'],
            types: ['all'],
            classes: ['all'],
            zones: ['all'],
            sharing: ['all'],
            graphdata: [],
            minval:0,
            dates: [],
            type: 1,
            isGraphType:true,
        })
    }

    onChangeZone = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ zones: ['all'] })
        } else {
            this.setState({ zones: e.filter((z) => z != 'all') })
        }
    }

    onChangeDivision = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ divisions: ['all'] })
        } else {
            this.setState({ divisions: e.filter((z) => z != 'all') })
        }
    }

    onChangeSharing = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ sharing: ['all'] })
        } else {
            this.setState({ sharing: e.filter((z) => z != 'all') })
        }
    }

    onChangeType = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ types: ['all'] })
        } else {
            this.setState({ types: e.filter((z) => z != 'all') })
        }
    }

    onChangeClass = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ classes: ['all'] })
        } else {
            this.setState({ classes: e.filter((z) => z != 'all') })
        }
    }

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    handleLineClick=(a,b,c)=>{
        //console.log(a);
        //console.log(b);
        //console.log(c);
    }

    render() {
        return (
            <div>
                <div className="FirstDiv" style={{ marginTop: 10 }}>
                    <span className="TitleOfTheBox">{strings.selectioncriteria}</span>
                    <Row xs={24} >
                        <Col md={4}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.floor}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' value={this.state.floorIds} style={{ width: '100%' }} onChange={(e) => this.setState({ floorIds: e })}>
                                {this.state.floors.map((f, i) => <Option key={i} value={f.id}>{f.name}</Option>)}
                            </Select>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.from}</span>
                                <div style={{ marginBottom: 16 }}>
                                    <DatePicker className="InputSizeOfThird" style={{ width: '100%' }} value={this.state.fromdate1} onChange={this.onfromChange} />
                                </div>
                            </div>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.to}</span>
                            </div>
                            <DatePicker className="InputSizeOfThird" style={{ width: '100%' }} value={this.state.todate1} onChange={this.ontoChange} />
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.zone}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' style={{ width: '100%' }} value={this.state.zones} onChange={(e) => this.onChangeZone(e)}>
                                <Option value={'all'}>{strings.all}</Option>
                                {ZONES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                            </Select>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.graphtype}</span>
                            </div>
                            <Select className="InputSizeOfThird" disabled={!this.state.isGraphType} style={{ width: '100%' }} value={this.state.type} onChange={(e) => this.onChangeGtype(e)}>
                                <Option value={1}>{strings.graphtype1}</Option>
                                <Option value={2}>{strings.graphtype2}</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.division}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' style={{ width: '100%' }} value={this.state.divisions} onChange={(e) => this.onChangeDivision(e)}>
                                <Option value={'all'}>{strings.all}</Option>
                                {DIVISIONS.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                            </Select>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.sharing}</span>
                            </div>
                            <Select className="InputSizeOfThird" style={{ width: '100%' }} mode='multiple' value={this.state.sharing} onChange={(e) => this.onChangeSharing(e)}>
                                <Option value={'all'}>{strings.all}</Option>
                                <Option value={"Y"}>{strings.yes}</Option>
                                <Option value={"N"}>{strings.no}</Option>
                            </Select>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.type}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' style={{ width: '100%' }} value={this.state.types} onChange={(e) => this.onChangeType(e)}>
                                <Option value={'all'}>{strings.all}</Option>
                                {TYPES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                            </Select>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.class}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' style={{ width: '100%' }} value={this.state.classes} onChange={(e) => this.onChangeClass(e)}>
                                <Option value={'all'}>{strings.all}</Option>
                                {SIZES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                            </Select>
                        </Col>
                    </Row>
                    <Button type="primary" size='large' style={{ marginRight: 17, marginTop: 10 }} loading={this.state.loading} onClick={this.searchData}>
                        {strings.search}
                    </Button>
                    <Button size='large' style={{ marginRight: 20, marginTop: 10 }} onClick={this.clearScrren}>
                        {strings.clear}
                    </Button>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Spin spinning={this.state.loading}>
                        {this.state.graphdata.length > 0 && <Row>
                            <Col span={6}></Col>
                            <Col span={12} style={{ padding: 10, background: 'white' }}>
                                <div style={{ border: '1px solid gray' }} id={'analystgraphdiv'}>
                                    {this.state.type === 2 && <ResponsiveContainer width={'100%'} height={400} >
                                        <LineChart data={this.state.graphdata} width={'100%'} height={'100%'}
                                            margin={{
                                                top: 15,
                                                bottom: 15,
                                                left: 5,
                                                right: 25
                                            }}>
                                            <XAxis dataKey="time" type='category' domain={['auto', 'auto']}
                                                interval={this.state.graphdata.length / 13} tickFormatter={(tick) => tick.split(':')[0] + ':00'} >
                                                <Label angle={0} position='right' style={{ textAnchor: 'middle' }}>
                                                    {strings.time}
                                                </Label>
                                            </XAxis>
                                            <YAxis allowDecimals={false}>
                                                <Label angle={270} position='center' style={{ textAnchor: 'middle' }}>
                                                    {strings.carparkvacancy}
                                                </Label>
                                            </YAxis>
                                            <Tooltip />
                                            <Legend />
                                            <ReferenceLine y={this.state.minval} isFront={false} label={<CustomizedLabel title={strings.minimumvalue +' = '+this.state.minval}/>} stroke="#00FF00" />
                                            <Line type="monotone" dataKey={'vaccancy'} name={strings.vaccancy} stroke={COLORS[0]} dot={false} 
                                            activeDot={<CustomizedDot color={COLORS[0]} onPointClick={(data)=>this.searchGraphPointData(data)}/>}/>
                                            
                                        </LineChart>
                                    </ResponsiveContainer>}
                                    {this.state.type === 1 &&
                                        <ResponsiveContainer width={'100%'} height={400} >
                                            <LineChart data={this.state.graphdata} width={'100%'} height={'100%'}
                                                margin={{
                                                    top: 15,
                                                    bottom: 15,
                                                    left: 5,
                                                    right: 25
                                                }}>
                                                <XAxis dataKey="date" type='category' domain={['auto', 'auto']}
                                                    interval={this.state.graphdata.length / 13} tickFormatter={(tick) => tick.split(':')[0] + ':00'} >
                                                    <Label angle={0} position='right' style={{ textAnchor: 'middle' }}>
                                                        {strings.time}
                                                    </Label>
                                                </XAxis>
                                                <YAxis allowDecimals={false}>
                                                    <Label angle={270} position='center' style={{ textAnchor: 'middle' }}>
                                                        {strings.carparkvacancy}
                                                    </Label>
                                                </YAxis>
                                                <Tooltip />
                                                <Legend />
                                                <ReferenceLine y={this.state.minval} isFront={false} label={<CustomizedLabel title={strings.minimumvalue +' = '+this.state.minval}/>} stroke="#00FF00" />
                                                {this.state.dates.map((d, i) => <Line type="monotone" dataKey={d} stroke={COLORS[i]} dot={false} 
                                                    activeDot={<CustomizedDot color={COLORS[i]} onPointClick={(data)=>this.searchGraphPointData(data)}/>}/>)}
                                                
                                            </LineChart>
                                        </ResponsiveContainer>}

                                </div>
                            </Col>
                            <Col span={6}></Col>
                        </Row>}
                        {this.state.graphdata.length === 0 && <Empty />}
                        <Row>
                            <Col span={22}></Col>
                            <Col span={2}><Button type="primary" size='large' disabled={this.state.graphdata.length === 0} onClick={this.exportExcel}>{strings.export}</Button></Col>
                        </Row>
                    </Spin>
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
                <Modal
                    title={strings.details}
                    visible={this.state.showDetailsModal}
                    onCancel={() => this.setState({ showDetailsModal: false })}
                    width={900}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => {this.setState({ showDetailsModal: false })}}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <div>
                    <Table columns={[
                        {
                            title: strings.time,
                            dataIndex: 'date',
                            key: 'date',
                            width:'20%'
                        },{
                            title: strings.vacancycount,
                            dataIndex: 'count',
                            key: 'count',
                            width:'20%'
                        }, {
                            title: strings.vaccancy,
                            dataIndex: 'resources',
                            key: 'resources',
                        }]} 
                        loading={this.state.loading} dataSource={this.state.detailsStrs} pagination={{ pageSize: 5 }}/>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default AnalystReport;

