import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col, Icon, Select, Tag, Radio, Button, DatePicker, Modal, InputNumber, Popconfirm, message } from "antd";
import Draggable from 'react-draggable';
import './App.css';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES, WASHROOM_ZONES, largeSlotWidth, WASHROOM_TYPES, WASHROOM_MAX_CAPACITY, ALL_FLOORS, smallSlotWidth, smallSlotHeight, floorPlanWidth, floorPlanHeight, NORMAL_ROLES, DTD_ROLES, ONLINE_ROLES, floorPlan_URL } from './data';
import yellowpin from './img/yellow_pin.png';
//import { Font, Page, Text, View, Document, StyleSheet, PDFViewer, Image, PDFDownloadLink } from '@react-pdf/renderer';
import font from './assets/msyh.ttf';
import moment from 'moment';
import ReactDOM from 'react-dom'
import * as d3 from 'd3';
import f1 from './assets/floor1.png';
import f2 from './assets/floor2.png';
import f3 from './assets/floor3.png';
import f4 from './assets/floor4.png';
import f5 from './assets/floor5.png';
import f6 from './assets/floor6.png';
import f7 from './assets/floor7.png';
import fM from './assets/floorM.png';
import fG from './assets/floorG.png';
import fz1 from './assets/floor1_zone_v3.png';
import fz2 from './assets/floor2_zone_v3.png';
import fz3 from './assets/floor3_zone_v3.png';
import fz4 from './assets/floor4_zone_v3.png';
import fz5 from './assets/floor5_zone_v3.png';
import fz6 from './assets/floor5_zone_v3.png';
import fz7 from './assets/floor5_zone_v3.png';
import fzM from './assets/floor5_zone_v3.png';
import fzG from './assets/floorG_zone_v3.png';
var QRCode = require('qrcode.react');
const Option = Select.Option;
const { CheckableTag } = Tag;
var locale = 'EN';
//Font.register({ family: 'msyh', src: font });
class Rect extends React.Component {
    componentDidMount() {
        if (this.props.isDraggable === true) {
            makeDraggable(this, this.props.posChange, this.props.cs.width/32, this.props.cs.height/12, this.props.cs.x, this.props.cs.y);
        }
    }

    componentDidUpdate(){
        if (this.props.isDraggable === true) {
            makeDraggable(this, this.props.posChange, this.props.cs.width/32, this.props.cs.height/12, this.props.cs.x, this.props.cs.y);
        }
    }
    render() {
        const { cs = {}, resource={} } = this.props;
        return <g transform={"translate(" + cs.x + ", " + cs.y + ")"} style={{cursor:'pointer'}} onClick={(e)=>this.props.editSlot(resource,e)}>            
            <rect width={cs.width} height={cs.height} transform={"rotate(" + cs.rotate + ")"} fill={cs.color && cs.color === 'red'? 'red' : 'white'} strokeWidth={0.5} stroke={cs.color ? cs.color : 'black'} fillOpacity={0.5}/>
            <text x={cs.x1} y={cs.y1} transform={"rotate(" + cs.rotate1 + ")"} fontFamily="Verdana" fontSize={cs.fontsize} fill="black" >{cs.spaceNo}</text>
        </g>
    }
}

const makeDraggable = (comp, posChange, w, h, x1, y1) => {
    let translateX = x1;
    let translateY = y1;
    const handleDrag = d3.drag()
        .subject(function () {
            const me = d3.select(this);
            return { x: translateX, y: translateY }
        })
        .on('drag', function (event) {
            const me = d3.select(this);
            var x = Math.round(event.x / w) * w;
            var y = Math.round(event.y / h) * h;
            const transform = `translate(${x}, ${y})`;
            translateX = x;
            translateY = y;
            posChange({ x: x, y: y },'pos')
            me.attr('transform', transform);
        });
    const node = ReactDOM.findDOMNode(comp);
    handleDrag(d3.select(node));
}

class AddResource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            resource: null,
            loading: false,
            rid: 0,
            type: RESOURCE_TYPES.ADMIN_CARPARK,
            floors: [],
            floor: '',
            triggerBack: false,
            showalertmodel: false,
            alertmessage: "",
            maxcapacity: 50,
            capacity: 1,
            eqs: [],
            nameZH: '',
            nameEN: '',
            latitude: 0,
            longitude: 0,
            stypes: [{value:'temperature,humidity', name:strings.tempnhumidity}, {value:'temperature,humidity,light', name:strings.temperaturehumiditylight}, 
                {value:'temperature,humidity,occupancy,light', name:strings.temphumilightoccu}, {value:'occupancy', name:strings.occupancy}, 
                {value:'airquality', name:strings.airquality}, {value:'light', name:strings.light}, {value:'carpark_sensor', name:strings.carparksensor}],
            wstypes: [{value:'occupancy', name:strings.occupancy}],           
            sensors:[],
            wsSensors:[{type:'occupancy',serialNumber:'',isAdded:1}],
            nameENerr: false,
            nameZHerr: false,
            longitudeerr: false,
            latitudeerr: false,
            top: -40,
            left: -1340,
            showqrmodel: false,
            qrcodedata: '',
            zoomaccounts: [],
            accountEmail: '',
            isCloud: 'N',
            accountEmailerr: false,
            passworderr: false,
            isDtd: 'N',
            floorloaded: false,
            sensorData:[],
            fontsize:8,
            charwidth:5,
            zone:'A',
            wtype:'male',
            currentDate:new Date(),
        }
    }

    resize = (e) => {        
        if(e.currentTarget.devicePixelRatio>=1.25){
            this.setState({fontsize:6, charwidth:3.86})
        }else if(e.currentTarget.devicePixelRatio>=1.10){
            this.setState({fontsize:7, charwidth:4.48})
        }else{
            this.setState({fontsize:8, charwidth:5.09})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    componentDidMount() {
        locale = strings.getLanguage()==='en'?'EN':'ZH'
        ApiHeader.userId = sessionStorage.getItem("userId");
        window.addEventListener('resize', this.resize)
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, zoomaccounts: sd.zoomAccounts, systemData: sd });
        var resource = sd.resource;
        var eqs = sd.equipment;        
        if(window.devicePixelRatio>=1.25){
            this.setState({fontsize:6,charwidth:3.86})
        }else if(window.devicePixelRatio>=1.10){
            this.setState({fontsize:7,charwidth:4.48})
        }else{
            this.setState({fontsize:8,charwidth:5.09})
        }
        if (this.props.isadd == true) {
            var floors = [];
            if (locale === 'EN') {
                floors = ALL_FLOORS;
            } else {
                floors = ALL_FLOORS;
            }
            for (var i = 0; i < eqs.length; i++) {
                eqs[i].checked = false;
            }    
            this.getAdminCarparks('3/F',sd)        
            this.setState({ floors: floors, floor: '3/F', eqs: eqs});
        } else {
            var md = this.props.md;
            if (this.props.iszoom) {
                this.setState({
                    rid: md.id, nameEN: md.nameEN, nameZH: md.nameZH,
                    type: md.resourceType, capacity: md.capacity, accountEmail: md.accountEmail, isCloud: md.isCloud
                });
            } else {

                var floor = '';
                if (locale == 'EN') {
                    floor = md.locationEN.split(',')[1]
                } else {
                    floor = md.locationEN.split(',')[1]
                }
                var maxcapacity = 0;
                if (md.resourceType === RESOURCE_TYPES.MEETING_ROOM || md.resourceType === RESOURCE_TYPES.ADMIN_ROOM) {                    
                    maxcapacity = 50;
                    this.setState({
                        rid: md.id, nameEN: md.nameEN, nameZH: md.nameZH, isDtd: md.isDtd == null ? 'N' : md.isDtd,
                        type: md.resourceType, capacity: md.capacity, latitude: md.latitude, longitude: md.longitude, sensors:md.sensors
                    });
                }else if (md.resourceType === RESOURCE_TYPES.WASHROOM) {                    
                    maxcapacity = WASHROOM_MAX_CAPACITY;
                    this.setState({
                        rid: md.id, nameEN: md.nameEN, nameZH: md.nameZH, zone:md.zone, wtype:md.type,
                        type: md.resourceType, capacity: md.capacity, latitude: md.latitude, longitude: md.longitude, wsSensors:md.sensors
                    });
                }else {                   
                    maxcapacity = 1;
                    this.setState({type: md.resourceType, capacity: md.capacity});
                    this.getAdminCarparks(floor.trim(),sd,md);                    
                }


                var floors = [];
                if (locale === 'EN') {
                    floors = ALL_FLOORS;
                } else {
                    floors = ALL_FLOORS;
                }
                if (md.equipmentsId) {
                    for (var i = 0; i < eqs.length; i++) {
                        for (var j = 0; j < md.equipmentsId.length; j++) {
                            if (md.equipmentsId[j] == eqs[i].id)
                                eqs[i].checked = true;
                        }
                    }
                }
                
                this.setState({ eqs: eqs, floors: floors, floor: floor.trim(), maxcapacity: maxcapacity });
                this.downloadQR()
                this.calculateLeft(md.latitude);
                this.calculateTop(md.longitude);
            }

        }
    }

    getAdminCarparks=(floor,sd,md)=>{
        this.setState({loading:true,rid:0})
        var rdata = {};
        var ids = [];
        var resource = sd.resourceTemp; 
        for(var i=0; i<resource.length; i++){
            if(RESOURCE_TYPES.ADMIN_CARPARK === resource[i].resourceType){
                if(locale === 'EN'){
                    if(resource[i].locationEN.split(',')[1].trim() === floor){
                        ids.push(resource[i].id);
                    }
                }else{
                    if(resource[i].locationEN.split(',')[1].trim() === floor){
                        ids.push(resource[i].id);
                    }
                }
            }
        }
        rdata.resourceIds = ids;
        if(this.state.nameEN != ''){
            rdata.nameEN=this.state.nameEN;
        }
        if(this.state.nameZH != ''){
            rdata.nameZH=this.state.nameZH;
        }
        fetch(apiurl+'resourcetemp/search1', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data;
                    for(var i=0;i<bs.length;i++){                        
                        bs[i].key = i;    
                        bs[i].iscarpark = true;                                                     
                    }
                    if(locale === 'EN'){
                        bs.sort((a, b) => a.nameEN.localeCompare(b.nameEN));
                    }else{
                        bs.sort((a, b) => a.nameZH.localeCompare(b.nameZH));
                    }
                    this.setState({sensorData:bs});
                    if(md != null){
                        this.editSlot(md);
                    }
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    addresource = () => {
        this.setState({ loading: true })
        var rdata = {};
        var isvalid = true;
        if (this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR) {
            rdata.capacity = this.state.capacity;
            rdata.rtype = this.state.type;
            if (this.state.nameEN != '') {
                rdata.nameEN = this.state.nameEN;
            } else {
                this.setState({ nameENerr: true })
                isvalid = false;
            }
            if (this.state.nameZH != '') {
                rdata.nameZH = this.state.nameZH;
            } else {
                this.setState({ nameZHerr: true })
                isvalid = false;
            }

            if (this.state.accountEmail != '') {
                rdata.accountEmail = this.state.accountEmail;
            } else {
                this.setState({ accountEmailerr: true })
                isvalid = false;
            }

            rdata.isCloud = this.state.isCloud;

            if (isvalid == false) {
                this.setState({ loading: false })
                return;
            }

            fetch(apiurl + 'zoomaccount/create', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {

                        this.fetchSystemData(true,true);

                    } else {
                        this.setState({ alertmessage: strings.error + (data.error != null ? data.error : data.error_code), showalertmodel: true })
                    }

                })
                .catch(e => console.warn(e))

        } else {

            var bfs = this.state.systemData.buildingFloor;
            var bf = null;
            for (var i = 0; i < bfs.length; i++) {
                if (bfs[i].floorEN == this.state.floor.replace(" ", "") || bfs[i].floorZH == this.state.floor.replace(" ", "")) {
                    rdata.bfid = bfs[i].id;
                    bf = bfs[i];
                    break;
                }
            }

            if (this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK) {
                var eqs = this.state.eqs;
                var eqids = [];
                for (var i = 0; i < eqs.length; i++) {
                    if (eqs[i].checked === true) {
                        eqids.push(eqs[i].id);
                    }
                }
                rdata.eqids = eqids;
                if (this.state.latitude != 0) {
                    rdata.latitude = this.state.latitude;
                } else {
                    this.setState({ latitudeerr: true })
                    message.error(strings.pleasefill+' '+strings.latitude);
                    isvalid = false;
                }

                if (this.state.longitude != 0) {
                    rdata.longitude = this.state.longitude;
                } else {
                    this.setState({ longitudeerr: true })
                    message.error(strings.pleasefill+' '+strings.longitude);
                    isvalid = false;
                }
                rdata.isDtd = this.state.isDtd;
            }


            rdata.capacity = this.state.capacity;
            rdata.slotInfo = JSON.stringify(this.state.slotInfo);
            rdata.rtype = this.state.type;

            if (this.state.nameEN != '') {
                rdata.locEN = this.state.nameEN + ', ' + bf.floorEN;
                rdata.nameEN = this.state.nameEN;
            } else {
                this.setState({ nameENerr: true })
                isvalid = false;
            }

            if (this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK) {
                if (this.state.nameZH != '') {
                    rdata.locZH = this.state.nameZH + ', ' + bf.floorZH;
                    rdata.nameZH = this.state.nameZH;
                } else {
                    this.setState({ nameZHerr: true })
                    isvalid = false;
                }
            } else {
                if (this.state.nameEN != '') {
                    rdata.locZH = this.state.nameEN + ', ' + bf.floorEN;
                    rdata.nameZH = this.state.nameEN;
                } else {
                    this.setState({ nameZHerr: true })
                    isvalid = false;
                }
            }

            if (isvalid == false) {
                this.setState({ loading: false })
                return;
            }

            if(this.state.type === RESOURCE_TYPES.ADMIN_CARPARK){
                if(this.state.sensors.length === 0){
                    rdata.sensors = [{type:'carpark_sensor',topic:'loraserver/parkingSensor/'+rdata.nameEN+'_1', isAdded:1}];
                }else{
                    var sensors = [];
                    for(var s of this.state.sensors){
                        if(s.type !== '' && s.topic !== '' ){
                            sensors.push(s);
                        }
                    }
                    rdata.sensors = sensors;
                }
            }else if(this.state.type === RESOURCE_TYPES.WASHROOM){                
                var sensors = [];
                for(var s of this.state.wsSensors){
                    if(s.type !== '' && s.serialNumber !== '' ){
                        sensors.push(s);
                    }
                }
                rdata.sensors = sensors;
                rdata.zone = this.state.zone;
                rdata.type = this.state.wtype;
            }else{
                var sensors = [];
                for(var s of this.state.sensors){
                    if(s.type !== '' && s.topic !== '' ){
                        sensors.push(s);
                    }
                }
                rdata.sensors = sensors;
            }            

            fetch(apiurl + (this.state.type === RESOURCE_TYPES.ADMIN_CARPARK ? 'resourcetemp/create' : 'resource/create'), {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        this.fetchSystemData(true,true);
                    } else {
                        this.setState({ alertmessage: strings.errorcode + '' + data.error_code, showalertmodel: true, loading: false });
                    }

                })
                .catch(e => console.warn(e))
        }
    }

    editresource = () => {
        this.setState({ loading: true })
        var rdata = {};
        var isvalid = true;
        if (this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR) {

            rdata.id = this.state.rid;
            rdata.capacity = this.state.capacity;
            rdata.rtype = this.state.type;
            if (this.state.nameEN != '') {
                rdata.nameEN = this.state.nameEN;
            } else {
                this.setState({ nameENerr: true })
                isvalid = false;
            }
            if (this.state.nameZH != '') {
                rdata.nameZH = this.state.nameZH;
            } else {
                this.setState({ nameZHerr: true })
                isvalid = false;
            }

            if (this.state.accountEmail != '') {
                rdata.accountEmail = this.state.accountEmail;
            } else {
                this.setState({ accountEmailerr: true })
                isvalid = false;
            }

            rdata.isCloud = this.state.isCloud;

            if (isvalid == false) {
                this.setState({ loading: false })
                return;
            }

            fetch(apiurl + 'zoomaccount/edit', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {

                        this.fetchSystemData(false,true);

                    } else {
                        this.setState({ alertmessage: strings.error + (data.error != null ? data.error : data.error_code), showalertmodel: true })
                    }

                })
                .catch(e => console.warn(e))

        } else {
            var bfs = this.state.systemData.buildingFloor;
            var bf = null;
            for (var i = 0; i < bfs.length; i++) {
                if (bfs[i].floorEN == this.state.floor.replace(" ", "") || bfs[i].floorZH == this.state.floor.replace(" ", "")) {
                    rdata.bfid = bfs[i].id;
                    bf = bfs[i];
                    break;
                }
            }

            if (this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK) {
                var eqs = this.state.eqs;
                var eqids = [];
                for (var i = 0; i < eqs.length; i++) {
                    if (eqs[i].checked === true) {
                        eqids.push(eqs[i].id);
                    }
                }

                rdata.eqids = eqids;
                rdata.isDtd = this.state.isDtd;
                if (this.state.latitude != '') {
                    rdata.latitude = this.state.latitude;
                } else {
                    this.setState({ latitudeerr: true })
                    isvalid = false;
                }

                if (this.state.longitude != '') {
                    rdata.longitude = this.state.longitude;
                } else {
                    this.setState({ longitudeerr: true })
                    isvalid = false;
                }
            }

            rdata.id = this.state.rid;
            rdata.resourceId = this.state.resourceId;
            rdata.capacity = this.state.capacity;
            rdata.slotInfo = JSON.stringify(this.state.slotInfo);
            rdata.rtype = this.state.type;

            if (this.state.nameEN != '') {
                rdata.locEN = this.state.nameEN + ', ' + bf.floorEN;
                rdata.nameEN = this.state.nameEN;
            } else {
                this.setState({ nameENerr: true })
                isvalid = false;
            }

            if (this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK) {
                if (this.state.nameZH != '') {
                    rdata.locZH = this.state.nameZH + ', ' + bf.floorZH;
                    rdata.nameZH = this.state.nameZH;
                } else {
                    this.setState({ nameZHerr: true })
                    isvalid = false;
                }
            } else {
                if (this.state.nameEN != '') {
                    rdata.locZH = this.state.nameEN + ', ' + bf.floorEN;
                    rdata.nameZH = this.state.nameEN;
                } else {
                    this.setState({ nameZHerr: true })
                    isvalid = false;
                }
            }


            if (isvalid == false) {
                this.setState({ loading: false })
                return;
            }

            if(this.state.type === RESOURCE_TYPES.ADMIN_CARPARK){
                if(this.state.sensors.length === 0){
                    rdata.sensors = [{type:'carpark_sensor',topic:'loraserver/parkingSensor/'+rdata.nameEN+'_1', isAdded:1}];
                }else{
                    var sensors = [];
                    for(var s of this.state.sensors){
                        if(s.type !== '' && s.topic !== '' ){
                            sensors.push(s);
                        }
                    }
                    rdata.sensors = sensors;
                }
            }else if(this.state.type === RESOURCE_TYPES.WASHROOM){                
                var sensors = [];
                for(var s of this.state.wsSensors){
                    if(s.type !== '' && s.serialNumber !== '' ){
                        sensors.push(s);
                    }
                }
                rdata.sensors = sensors;    
                rdata.zone = this.state.zone;   
                rdata.type = this.state.wtype;         
            }else{
                var sensors = [];
                for(var s of this.state.sensors){
                    if(s.type !== '' && s.topic !== '' ){
                        sensors.push(s);
                    }
                }
                rdata.sensors = sensors;
            }             

            fetch(apiurl + (this.state.type === RESOURCE_TYPES.ADMIN_CARPARK ? 'resourcetemp/edit' : 'resource/edit'), {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {

                        this.fetchSystemData(false,true);

                    } else {
                        this.setState({ alertmessage: strings.errorcode + '' + data.error_code, showalertmodel: true })
                    }

                })
                .catch(e => console.warn(e))

        }

    }

    fetchSystemData = (isadd, isclear) => {
        fetch(apiurl + 'system/list', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                this.setState({ loading: false })
                if (data.code == "1") {
                    sessionStorage.setItem('systemdata', JSON.stringify(data.data));
                    if(isclear){
                        this.clearScreen();
                    }else{
                        this.getAdminCarparks(this.state.floor,data.data)
                    }                
                    
                    if (!isadd) {
                        this.setState({ alertmessage: strings.updatesuccess, showalertmodel: true, triggerBack: true, systemData: data.data, loading: false, resource: data.data.resource, zoomaccounts: data.data.zoomAccounts });
                    } else {
                        this.setState({ systemData: data.data, loading: false, alertmessage: strings.updatesuccess, showalertmodel: true, resource: data.data.resource, zoomaccounts: data.data.zoomAccounts });
                    }
                } else {
                    this.setState({ alertmessage: strings.errorcode + '' + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    handleTypeChange = (value) => {
        if (value === RESOURCE_TYPES.ZOOM_MEETING || value === RESOURCE_TYPES.ZOOM_WEBINAR) {
            this.setState({ capacity: value === RESOURCE_TYPES.ZOOM_MEETING ? 200 : 500, type: value });
        } else {
            var resource = this.state.systemData.resource;
            var floors = [];
            if (locale === 'EN') {
                floors = ALL_FLOORS;
            } else {
                floors = ALL_FLOORS;
            }
            var maxcapacity = 0;
            if (value === RESOURCE_TYPES.MEETING_ROOM || value === RESOURCE_TYPES.ADMIN_ROOM) {
                maxcapacity = 50;
            } else if (value === RESOURCE_TYPES.WASHROOM) {
                maxcapacity = WASHROOM_MAX_CAPACITY;
            } else {
                maxcapacity = 1;
            }
            if(value === RESOURCE_TYPES.ADMIN_CARPARK){
                this.getAdminCarparks(floors[0],this.state.systemData);
            }
            this.setState({ maxcapacity: maxcapacity, floorloaded: false, type: value, floors: floors, floor: floors[0]});
        }

    }

    handleFloorChange = (value) => {
        this.setState({ floor: value});
        if(this.state.type === RESOURCE_TYPES.ADMIN_CARPARK){
            this.getAdminCarparks(value,this.state.systemData);
        }        
    }

    handleCapacityChange = (val) => {
        this.setState({ capacity: val })
    }

    handleIsDtdChange = (val) => {
        this.setState({ isDtd: val })
    }

    handlestypesChange = (val, i) => {
        var sensors = this.state.sensors;
        sensors[i].type = val;
        this.setState({sensors:[...sensors]})
    }

    handleswstypesChange = (val, i, label) => {
        var sensors = this.state.wsSensors;
        sensors[i][label] = val;
        this.setState({wsSensors:[...sensors]})
    }

    handlestypesChange1 = (val, i) => {
        var sd = this.state.sensorData;
        for(var s of sd){
            if(s.id === this.state.rid){
                var sensors = s.sensors;
                sensors[i].type = val;
                s.isEdited = 1;
                break;
            }
        }
        this.setState({sensorData:sd});
    }

    handleTopicChange1 = (e, i) => {
        var sd = this.state.sensorData;
        for(var s of sd){
            if(s.id === this.state.rid){
                var sensors = s.sensors;
                sensors[i].topic = e.target.value;
                s.isEdited = 1;
                break;
            }
        }
        this.setState({sensorData:sd});
    }

    handleTopicChange = (e, i) => {
        var sensors = this.state.sensors;
        sensors[i].topic = e.target.value;
        this.setState({sensors:[...sensors]})
    }

    removeSensor=(i)=>{
        var sensors = this.state.sensors;
        if(sensors[i].isAdded && sensors[i].isAdded === 1){
            var sensors1 = sensors.filter((s,index)=>i !== index);            
            this.setState({sensors:[...sensors1]})
        }else{
            sensors[i].isDelete = 1;
            this.setState({sensors:[...sensors]})
        }
    }

    removewsSensor=(i)=>{
        var sensors = this.state.wsSensors;
        if(sensors[i].isAdded && sensors[i].isAdded === 1){
            var sensors1 = sensors.filter((s,index)=>i !== index);
            if(sensors1.length===0){
                this.setState({wsSensors:[{type:'occupancy',serialNumber:'',isAdded:1}]})
            }else{
                this.setState({wsSensors:[...sensors1]})
            }
        }else{
            sensors[i].isDelete = 1;
            this.setState({wsSensors:[...sensors]})
        }
    }

    removeSensor1=(i)=>{
        var sd = this.state.sensorData;
        for(var s of sd){
            if(s.id === this.state.rid){
                var sensors = s.sensors;
                if(sensors[i].isAdded && sensors[i].isAdded === 1){
                    var sensors1 = sensors.filter((s,index)=>i !== index);
                    if(sensors1.length===0){
                        s.sensors = [{type:'temperature,humidity',topic:'',isAdded:1}];
                    }else{
                        s.sensors = [...sensors1];
                    }
                }else{
                    sensors[i].isDelete = 1;
                    s.sensors = [...sensors];
                }
                s.isEdited = 1;
                break;
            }
        }
        this.setState({sensorData:sd});
        
    }

    addSensor=()=>{
        this.setState({sensors:[...this.state.sensors,{type:'temperature,humidity',topic:'',isAdded:1}]})
    }

    addwsSensor=()=>{
        this.setState({wsSensors:[...this.state.wsSensors,{type:'occupancy',serialNumber:'',isAdded:1, isUnderMaintenance:0}]})
    }

    addSensor1=()=>{
        var sd = this.state.sensorData;
        for(var s of sd){
            if(s.id === this.state.rid){
                s.sensors = [...s.sensors, {type:'temperature,humidity',topic:'',isAdded:1}];
                s.isEdited = 1;
                break;
            }
        }
        this.setState({sensorData:sd});
    }

    clearScreen = () => {
        if (this.props.isadd == true) {
            if (this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR) {
                this.setState({ capacity: this.state.type === RESOURCE_TYPES.ZOOM_MEETING ? 200 : 500, nameEN: '', nameZH: '', accountEmail: '', isCloud: 'N' });
            } else {
                var eqs = this.state.eqs;
                for (var i = 0; i < eqs.length; i++) {
                    eqs[i].checked = false;
                }
                this.setState({
                    floor: '',
                    floors: [],
                    capacity: 1,
                    eqs: eqs,
                    nameEN: '',
                    nameZH: '',
                    longitude: 0,
                    latitude: 0,
                    top: -40,
                    left: -1340,
                    isDtd: 'N',
                    zone:'A',
                    wtype:'male'
                });
                var floors = [];
                if (locale === 'EN') {
                    floors = ALL_FLOORS;
                } else {
                    floors = ALL_FLOORS;
                }
                var maxcapacity = 0;
                if (this.state.type === RESOURCE_TYPES.MEETING_ROOM || this.state.type === RESOURCE_TYPES.ADMIN_ROOM) {
                    maxcapacity = 50;
                }else if (this.state.type === RESOURCE_TYPES.WASHROOM) {
                    maxcapacity = WASHROOM_MAX_CAPACITY;
                } else {
                    maxcapacity = 1;
                }

                this.setState({ maxcapacity: maxcapacity, floors: floors, floor: floors[0], 
                    sensors:[{type:'temperature,humidity',topic:'',isAdded:1}],
                    wsSensors:[{type:'occupancy',serialNumber:'',isAdded:1}]
                });
            }
        }
    }

    getFloorimgByName = (fname) => {
        if (fname === '1/F') {
          return 'floor1.png';
        } else if (fname === '2/F') {
          return 'floor2.png';
        } else if (fname === '3/F') {
          return 'floor3.png';
        } else if (fname === '4/F') {
          return 'floor4.png';
        } else if (fname === '5/F') {
          return 'floor5.png';
        } else if (fname === '6/F') {
          return 'floor6.png';
        } else if (fname === '7/F') {
          return 'floor7.png';
        } else if (fname === 'M/F') {
          return 'floorM.png';
        } else if (fname === 'G/F') {
          return 'floorG.png';
        }
    }

    getFloorimgByName1 = (fname) => {
        if (fname === '1/F') {
          return f1;
        } else if (fname === '2/F') {
          return f2;
        } else if (fname === '3/F') {
          return f3;
        } else if (fname === '4/F') {
          return f4;
        } else if (fname === '5/F') {
          return f5;
        } else if (fname === '6/F') {
          return f6;
        } else if (fname === '7/F') {
          return f7;
        } else if (fname === 'M/F') {
          return fM;
        } else if (fname === 'G/F') {
          return fG;
        }
    }

    getFloorZoneimgByName = (floor) => {
        if (floor === '7/F') {
            return fz7;
        } else if (floor === '6/F') {
            return fz6;
        } else if (floor === '5/F') {
            return fz5;
        } else if (floor === '4/F') {
            return fz4;
        } else if (floor === '3/F') {
            return fz3;
        } else if (floor === '2/F') {
            return fz2;
        } else if (floor === '1/F') {
            return fz1;
        } else if (floor === 'G/F') {
            return fzG;
        } else if (floor === 'M/F') {
            return fzM;
        }
    }

    handleeqsChange = (e, ed) => {
        var eqs = this.state.eqs;
        ed.checked = !ed.checked;
        eqs[ed.id - 1] = ed;
        this.setState({ eqs: eqs });
    }

    renderCapacity = () => {
        var data = [];
        for (var i = 1; i < this.state.maxcapacity + 1; i++) {
            data.push(<Option key={i} value={i}>{i}</Option>)
        }
        return data;
    }

    handleStop = (e, data) => {
        var x = data.lastX + 1340;
        var y = data.lastY + 40;
        var latitude = Math.floor((((x) * 2.8371)));
        var longitude = Math.floor(((y * 2.8477)));
        this.setState({ longitude: longitude, latitude: latitude });
        this.calculateLeft(latitude);
        this.calculateTop(longitude);
    }

    calculateLeft = (l) => {
        var left1 = Math.floor(((l / 2.8371)));
        this.setState({ left: left1 - 1340 });
    }

    calculateTop = (t) => {
        var top1 = Math.floor(((t / 2.8477)));
        this.setState({ top: top1 - 40 });
    }

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    generateQRCode = () => {
        this.setState({ showqrmodel: true });
    }

    downloadQR = () => {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas.toDataURL("image/png");
        this.setState({ qrcodedata: pngUrl })
    };

    /* MyDoc = () => (
        <Document>
            <Page size="A4" style={styles.page} orientation='landscape'>
                <View style={styles.section}>
                    <Text style={{ fontSize: 18, fontWeight: 'bold', textDecoration: 'underline', textUnderlinePosition: 'under' }}>Meeting Room Check-in Procedures:</Text>
                    <Text style={{ fontSize: 18, marginTop: 10, fontWeight: 'bold', textDecoration: 'underline', textUnderlinePosition: 'under' }}>會議室簽到程序</Text>
                    <View style={{ flexDirection: 'row', flexGrow: 1, marginTop: 30 }}>
                        <View style={{ flexGrow: 0.25 }}>
                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal' }}>1. Please log in the Smart</Text>
                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal', marginLeft: 15, marginTop: 5 }}>reservation system app in</Text>
                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal', marginLeft: 15, marginTop: 5 }}>your mobile 請先登入總部</Text>
                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal', marginLeft: 15, marginTop: 5 }}>預約系統手機應用程式</Text>

                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal', marginTop: 45 }}>2. Click the button to scan</Text>
                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal', marginLeft: 15, marginTop: 5 }}>the QR code on the right</Text>
                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal', marginLeft: 15, marginTop: 5 }}>and follow the indication</Text>
                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal', marginLeft: 15, marginTop: 5 }}>to confirm. 點擊圖中</Text>
                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal', marginLeft: 15, marginTop: 5 }}>的按鈕並掃描右邊的 QR</Text>
                            <Text style={{ fontSize: 15, width: 200, fontWeight: 'normal', marginLeft: 15, marginTop: 5 }}>碼根據提示完成確認</Text>
                        </View>
                        <View style={{ flexGrow: 0.25 }}>
                            <Image src={require('./assets/img1.JPG')} style={{ height: 105, width: 85, marginLeft: 40 }} />
                            <Image src={require('./assets/img2.JPG')} style={{ height: 200, width: 130, marginTop: 40 }} />
                        </View>
                        <View style={{ flexGrow: 0.5 }} render={({ qrcodedata }) => (
                            <View><Image source={{ uri: this.state.qrcodedata }} style={{ height: 220, width: 220, marginLeft: 145 }} />
                                <Text style={{ fontSize: 23, fontWeight: 'bold', marginLeft: 145, marginTop: 20 }}>Meeting Room {this.state.nameEN}</Text></View>
                        )} />
                    </View>
                </View>
            </Page>
        </Document>
    ) */

    downloadSettings = (type) => {
        this.setState({ loading: true })
        var rdata = {};
        rdata.type = type;
        rdata.id = this.state.rid;
        fetch(apiurl + 'zoomaccount/downloadSettings', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var datestr = `${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}`
                    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data.data, null, 2));
                    var dlAnchorElem = document.createElement('a');
                    dlAnchorElem.setAttribute("href", dataStr);
                    var filename = "";
                    if (type == 1) {
                        filename = "zoom_account_" + (this.state.nameEN) + "_current_settings_" + datestr + ".json";
                    } else {
                        filename = "zoom_account_" + (this.state.nameEN) + "_default_settings_" + datestr + ".json";
                    }
                    dlAnchorElem.setAttribute("download", filename);
                    dlAnchorElem.click();

                    document.removeChild(dlAnchorElem);
                } else {
                    this.setState({ alertmessage: strings.error + (data.error != null ? data.error : data.error_code), showalertmodel: true })
                }
                this.setState({ loading: false })

            })
            .catch(e => { this.setState({ loading: false }); console.warn(e) })
    }

    restoreSettings = () => {
        this.setState({ loading: true })
        var rdata = {};
        rdata.id = this.state.rid;
        fetch(apiurl + 'zoomaccount/restoreSettings', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    this.setState({ alertmessage: strings.updatesuccess, showalertmodel: true, loading: false })
                } else {
                    this.setState({ alertmessage: strings.error + (data.error != null ? data.error : data.error_code), showalertmodel: true, loading: false })
                }

            })
            .catch(e => { this.setState({ loading: false }); console.warn(e) })
    }

    calculateSlotInfo = (slotInfo, text, color) => {
        var currentFloorplanWidth = document.getElementById('floorplan').clientWidth;
        var currentFloorplanHeight = document.getElementById('floorplan').clientHeight;
        var w = 0, h = 0, width = 0, height = 0, rotate = 0, rotate1 = 0, x1 = 0, y1 = 0;
        if (slotInfo.size === 'S') {
            w = (currentFloorplanWidth * smallSlotWidth) / floorPlanWidth;
            h = (currentFloorplanHeight * smallSlotHeight) / floorPlanHeight;
        } else {
            w = (currentFloorplanWidth * largeSlotWidth) / floorPlanWidth;
            h = (currentFloorplanHeight * smallSlotHeight) / floorPlanHeight;
        }
        var fontsize = this.state.fontsize;
        var charwidth = this.state.charwidth;
        var textWidth = charwidth * text.length
        if (slotInfo.direction === 'H') {
            width = w;
            height = h;
            if (slotInfo.textPos === 'U') {
                x1 = (width - textWidth) / 2;
                y1 = -3;
            }else if (slotInfo.textPos === 'L')  {
                x1 = (width - textWidth) / 2;
                y1 = h + fontsize;
            }else if (slotInfo.textPos === 'LF') {                
                x1 = -(textWidth+2);
                y1 = h-(fontsize/2);
            }else if (slotInfo.textPos === 'RI') {
                x1 = width+2;
                y1 = h-(fontsize/2);
            }
        } else if (slotInfo.direction === 'V') {
            width = w;
            height = h;
            rotate = 90;
            rotate1 = 90;
            if (slotInfo.textPos === 'RI') {
                x1 = (width - textWidth) / 2;
                y1 = -3;
            }else if (slotInfo.textPos === 'LF')  {
                x1 = (width - textWidth) / 2;
                y1 = h + fontsize;
            }else if (slotInfo.textPos === 'U') {                
                x1 = -(textWidth+2);
                y1 = h-(fontsize/2);
            }else if (slotInfo.textPos === 'L') {
                x1 = width+2;
                y1 = h-(fontsize/2);
            }
        } else if (slotInfo.direction === 'R') {
            width = w;
            height = h;
            rotate = 45;
            rotate1 = 45;
            if (slotInfo.textPos === 'U') {
                x1 = (width - textWidth) / 2;
                y1 = -3;
            }else if (slotInfo.textPos === 'L')  {
                x1 = (width - textWidth) / 2;
                y1 = h + fontsize;
            }else if (slotInfo.textPos === 'LF') {                
                x1 = -(textWidth+2);
                y1 = h-(fontsize/2);
            }else if (slotInfo.textPos === 'RI') {
                x1 = width+2;
                y1 = h-(fontsize/2);
            }
        } else if (slotInfo.direction === 'L') {
            width = w;
            height = h;
            rotate = 315;
            rotate1 = 315;
            if (slotInfo.textPos === 'U') {
                x1 = (width - textWidth) / 2;
                y1 = -3;
            }else if (slotInfo.textPos === 'L')  {
                x1 = (width - textWidth) / 2;
                y1 = h + fontsize;
            }else if (slotInfo.textPos === 'LF') {                
                x1 = -(textWidth+2);
                y1 = h-(fontsize/2);
            }else if (slotInfo.textPos === 'RI') {
                x1 = width+2;
                y1 = h-(fontsize/2);
            }
        }
        return { width: width, height: height, fontsize:fontsize,  x: (slotInfo.x * currentFloorplanWidth) / floorPlanWidth, y: (slotInfo.y * currentFloorplanHeight) / floorPlanHeight, x1: x1, y1: y1, rotate: rotate, rotate1: rotate1, spaceNo: text, color: color };
    }

    saveCarparks=()=>{
        var sd = this.state.sensorData;
        var names = [];
        for(var s of sd){
            if(s.isDelete !== 1){
                if(s.nameEN === null || s.nameEN === ''){
                    message.warn(strings.plsentervalidslotno);
                    return;
                }

                if(!names.includes(s.nameEN)){
                    names.push(s.nameEN);
                }else{
                    message.warn(strings.plsenteruniqueslotno);
                    return;
                }
                if(s.sensors.length === 0){
                    s.sensors = [{topic :'loraserver/parkingSensor/'+s.nameEN+'_1', type: 'carpark_sensor'}];                   
                }
            }            
        }
        this.setState({loading:true,rid:0})
        fetch(apiurl + 'resourcetemp/savecarpark', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(sd),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                this.fetchSystemData(true,false);
            } else {
                this.setState({ alertmessage: strings.errorcode + '' + data.error_code, showalertmodel: true, loading: false });
            }

        })
        .catch(e => console.warn(e))
    }

    addCarpark=()=>{
        if(this.validateCarpark()){
            var bfs = this.state.systemData.buildingFloor;
            var bf = null;
            for (var i = 0; i < bfs.length; i++) {
                if (bfs[i].floorEN == this.state.floor.replace(" ", "") || bfs[i].floorZH == this.state.floor.replace(" ", "")) {                
                    bf = bfs[i];
                    break;
                }
            }
            var ts = moment().unix();
            var res = JSON.parse('{"id":'+ts+',"isAdded":1,"buildingFloorId":'+bf.id+',"capacity":1,"isVirtual":0,"resourceType":"admin_carpark","direction":"h","isDtd":"N","locationEN":"","nameEN":"","locationZH":"","nameZH":"","longitude":"0","latitude":"0","isReminderEnabled":0,"isLightIncluded":0,"type":"","zone":"B","shared":"N","remark":"","size":"","divisions":"[]","sensorData":{"carpark_sensor_topic":""}}');
            res.slotInfo =  '{ "x": 40, "y": 40, "direction": "V", "textPos": "U", "size": "S" }';
            res.sensors = [];
            this.setState({sensorData:[...this.state.sensorData, res], rid: ts});
        }
        
    }

    editSlot=(md,e)=>{   
        if(this.validateCarpark()){
            this.setState({rid: md.id, sensorData:[...this.state.sensorData], sensors:md.sensors});
            if(e){
                e.stopPropagation();
            }
        }
        
    }

    clearSelection=()=>{
        if(this.validateCarpark()){
            this.setState({rid: 0});
        }
    }

    validateCarpark=()=>{
        if(this.state.rid === 0){
            return true;
        }
        var sd = this.state.sensorData;
        for(var s of sd){
            if(s.id === this.state.rid){
                if(s.nameEN === null || s.nameEN === ''){
                    message.warn(strings.plsentervalidslotno);
                    return false;
                }
                if(sd.filter((s1)=>s1.nameEN === s.nameEN).length>1){
                    message.warn(strings.plsenteruniqueslotno);
                    return false;
                }
                break;
            }
        }
        return true;
    }

    removeCarpark=()=>{
        var sd = this.state.sensorData;
        for(var s of sd){
            if(s.id === this.state.rid){
                s.isDelete = 1;
                break;
            }
        }
        this.setState({sensorData:sd, rid: 0})
    }

    handleSlotInfoChange=(val,type)=>{
        var sd = this.state.sensorData;
        for(var s of sd){
            if(s.id === this.state.rid){
                if(type === 'no'){
                    s.nameEN = val;
                    s.nameZH = val;
                    var bfs = this.state.systemData.buildingFloor;
                    var bf = null;
                    for (var i = 0; i < bfs.length; i++) {
                        if (bfs[i].id == s.buildingFloorId) {                            
                            bf = bfs[i];
                            break;
                        }
                    }
                    s.locationEN = val + ', ' + bf.floorEN;
                    s.locationZH = val + ', ' + bf.floorZH;
                }else if(type === 'size' || type === 'textPos' || type === 'direction'){
                    var si = JSON.parse(s.slotInfo);
                    si[type] = val;
                    s.slotInfo = JSON.stringify(si);
                }else if(type === 'topic'){
                    s.sensorData.carpark_sensor_topic = val;
                }else if(type === 'pos'){
                    var si = JSON.parse(s.slotInfo);
                    si.x = this.getXpos(val.x);
                    si.y = this.getYpos(val.y);
                    s.slotInfo = JSON.stringify(si);
                }
                s.isEdited = 1;
                break;
            }
        }
        this.setState({sensorData:sd});
    }

    getXpos = (x) => {
        var currentFloorplanWidth = document.getElementById('floorplan').clientWidth;
        return (x * floorPlanWidth) / currentFloorplanWidth;
    }

    getYpos = (y) => {
        var currentFloorplanHeight = document.getElementById('floorplan').clientHeight;
        return (y * floorPlanHeight) / currentFloorplanHeight;
    }

    getSelectedSlot=(rid)=>{
        var sd = this.state.sensorData;
        if(rid === 0){
            return null;
        }else{
            for(var s of sd){
                if(s.id === this.state.rid){
                    return s;
                }
            }
        }
    }

    renderReacts=()=>{
        var data = [];
        this.state.sensorData.map((cs, i) => cs.isDelete === 1?'':
                        data.push(<Rect key={i} resource={cs} posChange={this.handleSlotInfoChange} editSlot={this.editSlot} cs={this.calculateSlotInfo(JSON.parse(cs.slotInfo), cs.nameEN, this.state.rid === cs.id?'red':'black')} isDraggable={this.state.rid === cs.id?true:false} />))

        return data;
    }

    editMaintenance=(sd,val,index)=>{        
        var rdata = {id:sd.id,value:val};    
        fetch(apiurl+'resource/editMaintenance', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var sds = this.state.wsSensors;
                    sds[index].isUnderMaintenance = val;
                    this.setState({wsSensors:sds,alertmessage:strings.updatesuccess,showalertmodel:true})
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    render() {
        const selectedCarpark = this.getSelectedSlot(this.state.rid);
        const {sensorData = []} = this.state;
        return (
            <div style={{ marginTop: 10 }} className='Tab2Div'>
                <div className="FirstDiv" style={{ paddingTop: 0, marginBottom:20 }}>
                    <div hidden><QRCode value={this.props.md != null ? "roomid=" + this.props.md.id : "roomid="} size={220} id="123456" /></div>
                    {this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && this.props.isadd ?
                        <Row xs={24} ><Col md={2} ><span className="TitleOfTheBox">{strings.addresource}</span></Col></Row> :
                        this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && this.props.isedit ?
                            <Row xs={24}  ><Col md={2} ><span className="TitleOfTheBox">{strings.editresource}</span></Col></Row> :''
                    }

                    {!this.props.isedit && <Row xs={24}>
                        <Col md={2} ><span className="TitleOfTheBox">{strings.resourcedetail}</span></Col>
                    </Row>}
                    {this.props.isedit && <Row xs={24}>
                        <Col md={2} ><span className="TitleOfTheBox">{strings.resourcedetail}</span></Col>
                        <Col md={4} >
                            {this.state.type === RESOURCE_TYPES.ADMIN_CARPARK && <Button type="primary" onClick={this.addCarpark}>{strings.add}</Button>}
                            {this.state.type === RESOURCE_TYPES.ADMIN_CARPARK && <Button type={'primary'} onClick={this.removeCarpark} disabled={this.state.rid === 0} style={{ marginLeft: 10, background: '#fff', color: 'red', borderColor: 'red' }}>{strings.remove}</Button>}
                            {((this.state.type === RESOURCE_TYPES.ADMIN_CARPARK && selectedCarpark) || (this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK)) && <Button type="primary" style={{ marginLeft: 10}} onClick={()=>this.state.type === RESOURCE_TYPES.ADMIN_CARPARK?this.addSensor1() : this.state.type === RESOURCE_TYPES.WASHROOM? this.addwsSensor() : this.addSensor()}>{strings.addSensor}</Button>}
                        </Col>
                    </Row>}

                    <Row xs={24} style={{ marginTop: 5 }} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className="DateText">{strings.type}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={this.state.type} onChange={this.handleTypeChange} disabled={!this.props.isadd}>                                    
                                    {(NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) || DTD_ROLES.includes(sessionStorage.getItem("userrole"))) && <Option value={RESOURCE_TYPES.MEETING_ROOM}>{strings.meetingroom}</Option>}
                                    {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.ADMIN_ROOM}>{strings.fixedroom}</Option>}
                                    {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.ADMIN_CARPARK}>{strings.fixedcarpark}</Option>}
                                    {ONLINE_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.ZOOM_MEETING}>{strings.zoommeeting}</Option>}
                                    {ONLINE_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.ZOOM_WEBINAR}>{strings.zoomwebinar}</Option>}
                                    {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.WASHROOM}>{strings.washroom}</Option>}
                                </Select>
                            </div>
                        </Col>
                        {this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR && <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className="DateText">{strings.floor}</span>
                        </Col>}
                        {this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR && <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={this.state.floor} onChange={this.handleFloorChange} disabled={!this.props.isedit}>
                                    {
                                        this.state.floors.map((ed,index) => <Option key={index} value={ed}>{ed}</Option>)
                                    }
                                </Select>
                            </div>
                        </Col>}

                        {(this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR) && <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className="DateText">{strings.capacity}</span>
                        </Col>}
                        {(this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR) && <Col md={6}>
                            <div>
                                <InputNumber min={1} max={this.state.type === RESOURCE_TYPES.ZOOM_MEETING ? 200 : 500} className='bookingDatebox2' value={this.state.capacity} onChange={e => this.setState({ capacity: e })} disabled={!this.props.isedit} />
                            </div>
                        </Col>}
                    </Row>

                    {this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && <Row xs={24} style={{ marginTop: 10, }} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className={this.state.nameZHerr ? "DateTextErr" : "DateText"}>{strings.chinesename}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Input size="large" className='bookingDatebox2' style={{ height: 32 }} value={this.state.nameZH} placeholder={strings.pleasefill + ' ' + strings.chinesename} onChange={e => this.setState({ nameZH: e.target.value })} disabled={!this.props.isedit} />
                            </div>
                        </Col>
                        <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className={this.state.nameENerr ? "DateTextErr" : "DateText"}>{strings.englishname}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Input size="large" className='bookingDatebox2' style={{ height: 32 }} value={this.state.nameEN} placeholder={strings.pleasefill + ' ' + strings.englishname} onChange={e => this.setState({ nameEN: e.target.value })} disabled={!this.props.isedit} />
                            </div>
                        </Col>
                    </Row>}
                    {(this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR) && <Row xs={24} style={{ marginTop: 10, }} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className={this.state.accountEmailerr ? "DateTextErr" : "DateText"}>{strings.zoomemail}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Input size="large" className='bookingDatebox2' style={{ height: 32 }} value={this.state.accountEmail} placeholder={strings.pleasefill + ' ' + strings.zoomemail} onChange={e => this.setState({ accountEmail: e.target.value })} disabled={!this.props.isedit} />
                            </div>
                        </Col>
                        <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className={"DateText"}>{strings.iscloud}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={this.state.isCloud} onChange={(e) => this.setState({ isCloud: e })} disabled={!this.props.isedit}>
                                    <Option value="Y">{strings.yes}</Option>
                                    <Option value="N">{strings.no}</Option>
                                </Select>
                            </div>
                        </Col>
                    </Row>}
                    {this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR && 
                    this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && <Row xs={24} style={{ marginTop: 10, }} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className={this.state.descerr ? "DateTextErr" : "DateText"}>{this.state.type === RESOURCE_TYPES.WASHROOM ? strings.wcapacity : strings.capacity}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={this.state.capacity} onChange={this.handleCapacityChange} disabled={!this.props.isedit}>
                                    {
                                        this.renderCapacity()
                                    }
                                </Select>
                            </div>
                        </Col>
                        {(this.state.type === RESOURCE_TYPES.MEETING_ROOM || this.state.type === RESOURCE_TYPES.ADMIN_ROOM) && <Col md={4}>
                            <span style={{ marginLeft: 26 }} className="DateText">{strings.equipment}</span>
                        </Col>}
                        {(this.state.type === RESOURCE_TYPES.MEETING_ROOM || this.state.type === RESOURCE_TYPES.ADMIN_ROOM) && <Col md={6}>
                            <div>
                                {this.state.eqs.map((ed, index) => <CheckableTag key={index} checked={ed.checked} onChange={(e) => this.handleeqsChange(e, ed)}>{locale == 'EN' ? ed.nameEN : ed.nameZH}</CheckableTag>)}
                            </div>
                        </Col>}
                        {this.state.type === RESOURCE_TYPES.WASHROOM && <Col md={4}>
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className="DateText">{strings.zone}</span>
                        </Col>}
                        {this.state.type === RESOURCE_TYPES.WASHROOM && <Col md={6} >
                            <div>
                                <Select className='bookingDatebox2' value={this.state.zone} onChange={(e)=>this.setState({zone:e})} disabled={!this.props.isedit}>
                                    {WASHROOM_ZONES.map((z,zi)=><Option key={zi} value={z}>{z}</Option>)}
                                </Select>
                            </div>
                        </Col>}
                    </Row>}
                    {this.state.type === RESOURCE_TYPES.WASHROOM && <Row xs={24} style={{ marginTop: 10, }} >
                        <Col md={2} ></Col>
                        <Col md={4}>
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className="DateText">{strings.wtype}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={this.state.wtype} onChange={(e) => this.setState({wtype:e})} disabled={!this.props.isedit}>
                                    {
                                        WASHROOM_TYPES.map((ed, index) => <Option key={index} value={ed.toLowerCase()}>{strings[ed]}</Option>)
                                    }
                                </Select>
                            </div>
                        </Col>
                    </Row>}
                    {(this.state.type === RESOURCE_TYPES.MEETING_ROOM || this.state.type === RESOURCE_TYPES.ADMIN_ROOM) && <Row xs={24} style={{ marginTop: 10, }} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className={this.state.descerr ? "DateTextErr" : "DateText"}>{strings.isdtdroom}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={this.state.isDtd} onChange={this.handleIsDtdChange} disabled={!this.props.isedit}>
                                    <Option value={'Y'}>{strings.yes}</Option>
                                    <Option value={'N'}>{strings.no}</Option>
                                </Select>
                            </div>
                        </Col>
                    </Row>}
                    {this.state.type === RESOURCE_TYPES.ADMIN_CARPARK && <Row xs={24} style={{ marginTop: 10 }} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className="DateText">{strings.slotno}</span>
                        </Col>
                        <Col md={6} >
                            <div>
                                <Input size="large" className='bookingDatebox2' style={{ height: 32 }} value={!selectedCarpark?'':selectedCarpark.nameEN} placeholder={strings.pleasefill + ' ' + strings.slotno} onChange={e => this.handleSlotInfoChange(e.target.value,'no')} disabled={!this.props.isedit} />
                            </div>
                        </Col>
                        <Col md={4} >
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className="DateText">{strings.size}</span>
                        </Col>
                        <Col md={6} >
                            <div>
                                <Select className='bookingDatebox2' value={!selectedCarpark?'S':JSON.parse(selectedCarpark.slotInfo).size} onChange={(e) => this.handleSlotInfoChange(e,'size')} disabled={!this.props.isedit}>
                                    <Option value="S">{strings.small}</Option>
                                    <Option value="L">{strings.large}</Option>
                                </Select>
                            </div>
                        </Col>
                    </Row>}
                    {this.state.type === RESOURCE_TYPES.ADMIN_CARPARK && <Row xs={24} style={{ marginTop: 10 }} >
                        <Col md={2} ></Col>

                        <Col md={4}>
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className="DateText">{strings.textposition}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={!selectedCarpark?'U':JSON.parse(selectedCarpark.slotInfo).textPos} onChange={(e) => this.handleSlotInfoChange(e,'textPos')} disabled={!this.props.isedit}>
                                    <Option value="U">{strings.upper}</Option>
                                    <Option value="L">{strings.lower}</Option>
                                    <Option value="LF">{strings.left}</Option>
                                    <Option value="RI">{strings.right}</Option>
                                </Select>
                            </div>
                        </Col>
                        <Col md={4}>
                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                            <span style={{ marginLeft: 12 }} className="DateText">{strings.direction}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={!selectedCarpark?'V':JSON.parse(selectedCarpark.slotInfo).direction} onChange={(e) => this.handleSlotInfoChange(e,'direction')} disabled={!this.props.isedit}>
                                    <Option value="V">{strings.vertical}</Option>
                                    <Option value="H">{strings.horizontal}</Option>
                                    <Option value="R">{'45°'}</Option>
                                    <Option value="L">{'315°'}</Option>
                                </Select>
                            </div>
                        </Col>
                    </Row>}
                    {(this.state.type === RESOURCE_TYPES.MEETING_ROOM || this.state.type === RESOURCE_TYPES.ADMIN_ROOM) && 
                        this.state.sensors.map((sensor, index)=>sensor.isDelete === 1?'':<Row key={index} xs={24} style={{ marginTop: 10, }} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                            <span style={{ marginLeft: 26 }} className="DateText">{strings.sensor}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={sensor.type} onChange={(e) => this.handlestypesChange(e, index)} disabled={!this.props.isedit}>
                                    <Option value=''>{strings.pleaseselect}</Option>
                                    {
                                        this.state.stypes.map((ed, index) => <Option key={index} value={ed.value.toLowerCase()}>{ed.name}</Option>)
                                    }
                                </Select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Input size="large" style={{ height: 32 }} value={sensor.topic} placeholder="Please Fill" onChange={e => this.handleTopicChange(e,index)} disabled={!this.props.isedit} />
                            </div>
                        </Col>
                        {this.props.isedit && <Col md={4}>
                            <Button type="primary" size="small" style={{ marginLeft: 10, marginTop:3, background: '#fff', color: 'red', borderColor: 'red' }} onClick={()=>this.removeSensor(index)}>{strings.remove}</Button>
                            
                        </Col>}
                    </Row>)}

                    {this.state.type === RESOURCE_TYPES.WASHROOM && 
                        this.state.wsSensors.map((sensor, index)=>sensor.isDelete === 1?'':<Row key={index} xs={24} style={{ marginTop: 10, }} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                            <span style={{ marginLeft: 26 }} className="DateText">{strings.sensor}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={sensor.type} onChange={(e) => this.handleswstypesChange(e, index, 'type')} disabled={!this.props.isedit}>
                                    <Option value=''>{strings.pleaseselect}</Option>
                                    {
                                        this.state.wstypes.map((ed, index) => <Option key={index} value={ed.value.toLowerCase()}>{ed.name}</Option>)
                                    }
                                </Select>
                            </div>
                        </Col>                        
                        <Col md={5}>
                            <div>
                                <Input size="large" style={{ height: 32, marginLeft:10,width:'95%', }} value={sensor.serialNumber} placeholder="Please fill sensor id" onChange={e => this.handleswstypesChange(e.target.value,index,'serialNumber')} disabled={!this.props.isedit} />
                            </div>
                        </Col>
                        {this.props.isedit && <Col md={4}>
                            <Button type="primary" size="small" style={{ marginLeft: 10, marginTop:3, background: '#fff', color: 'red', borderColor: 'red' }} onClick={()=>this.removewsSensor(index)}>{strings.remove}</Button>
                            {this.props.isadd ? '' : sensor.isUnderMaintenance === 0?
                                <Button type="primary" size="small" style={{ marginLeft: 10, marginTop:3, }} onClick={()=>this.editMaintenance(sensor,1,index)}>{strings.maintenance1}</Button>:
                                <Button size="small" style={{ marginLeft: 10, marginTop:3 }} onClick={()=>this.editMaintenance(sensor,0,index)}>{strings.resume}</Button>} 
                        </Col>}
                    </Row>)} 

                    {this.state.type === RESOURCE_TYPES.ADMIN_CARPARK && selectedCarpark &&
                        selectedCarpark.sensors.map((sensor, index)=>sensor.isDelete === 1?'':<Row key={index} xs={24} style={{ marginTop: 10, }} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                            <span style={{ marginLeft: 26 }} className="DateText">{strings.sensor}</span>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Select className='bookingDatebox2' value={sensor.type} onChange={(e) => this.handlestypesChange1(e, index)} disabled={!this.props.isedit}>
                                    <Option value=''>{strings.pleaseselect}</Option>
                                    {
                                        this.state.stypes.map((ed, index) => <Option key={index} value={ed.value.toLowerCase()}>{ed.name}</Option>)
                                    }
                                </Select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Input size="large" style={{ height: 32 }} value={sensor.topic} placeholder="Please Fill" onChange={e => this.handleTopicChange1(e,index)} disabled={!this.props.isedit} />
                            </div>
                        </Col>
                        {this.props.isedit && <Col md={4}>
                            <Button type="primary" size="small" style={{ marginLeft: 10, marginTop:3, background: '#fff', color: 'red', borderColor: 'red' }} onClick={()=>this.removeSensor1(index)}>{strings.remove}</Button>
                            
                        </Col>}
                    </Row>)} 
                                       
                    {this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR && this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && <Row xs={24} style={{ marginTop: 10, }} hidden={this.state.stypes.length != 2}>
                        <Col md={2} ></Col>
                        <Col md={15} >
                            <span className="LongText2" style={{ marginLeft: 25, }}>{strings.pleasedragpinetc}</span>
                        </Col>
                    </Row>}
                    {this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR && this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && <Row xs={24} style={{ marginTop: 5, }}  >
                        <Col md={2} ></Col>
                        <Col md={20} >
                            <img src={floorPlan_URL+this.getFloorimgByName(this.state.floor)+'?v='+this.state.currentDate} style={{ height: 440, width: 1320, background: 'white' }} alt='Floor'></img>
                            <Draggable
                                handle=".handle"
                                defaultPosition={{ x: -1340, y: -40 }}
                                position={{ x: this.state.left, y: this.state.top }}
                                scale={1}
                                bounds={{ top: -40, left: -1340, right: 0, bottom: 400 }}
                                onStop={this.handleStop}>
                                <img draggable="false" className='handle' src={yellowpin} style={{ position: 'absolute', width: 40, height: 40, cursor: 'move', }} />
                            </Draggable>

                        </Col>
                        <Col md={2} ></Col>
                    </Row>}
                    
                    {this.state.type === RESOURCE_TYPES.ADMIN_CARPARK && <Row xs={24} style={{ marginTop: 5, }}  >
                        <Col md={2} ></Col>
                        <Col md={20} >
                        
                            <img src={this.getFloorZoneimgByName(this.state.floor)} style={{ width: '100%', background: 'transparent', position: 'absolute' }} alt='FloorZone' ></img>
                            {this.state.floorloaded && <svg x={0} y={0} onClick={()=>this.clearSelection()} width={'100%'} height={'100%'} style={{ cursor:'pointer',position: 'absolute' }}>
                                {this.renderReacts()}                                
                            </svg>}
                            <img src={this.getFloorimgByName1(this.state.floor)} style={{ width: '100%', background: 'white' }} alt='Floor' onLoad={() => this.setState({ floorloaded: true })} id="floorplan"></img>
                        </Col>
                        <Col md={2} ></Col>
                    </Row>}
                    <Row xs={24} style={{ marginTop: this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR ? 5 : 55, }} >
                        <Col md={2} ></Col>
                        <Col md={18} >
                            {this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && <Button type="primary" size='large' style={{ marginRight: 17 }} loading={this.state.loading} onClick={() => this.addresource()} hidden={!this.props.isadd}>
                                {strings.create}
                            </Button>}
                            {this.state.type === RESOURCE_TYPES.ADMIN_CARPARK && <Button type="primary" size='large' style={{ marginRight: 17 }} loading={this.state.loading} onClick={() => this.saveCarparks()}>
                                {strings.save}
                            </Button>}
                            {this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && <Button type="primary" size='large' style={{ marginRight: 17 }} loading={this.state.loading} onClick={() => this.editresource()} hidden={this.props.isadd || !this.props.isedit}>
                                {strings.update}
                            </Button>}
                            {this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && <Button size='large' style={{ marginRight: 20 }} onClick={() => this.clearScreen()} hidden={!this.props.isadd}>
                                {strings.clear}
                            </Button>}
                            {this.state.type === RESOURCE_TYPES.ADMIN_CARPARK && <Button onClick={() => this.getAdminCarparks(this.state.floor,this.state.systemData)}>{strings.reset}</Button>}
                            {!this.props.isadd && <Button size='large' type="primary" style={{ marginRight: 20 }} onClick={() => this.downloadSettings(1)} hidden={this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR}>
                                {strings.downloadcurrentSettings}
                            </Button>}
                            {!this.props.isadd && <Button size='large' type="primary" style={{ marginRight: 20 }} onClick={() => this.downloadSettings(2)} hidden={this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR}>
                                {strings.downloaddefaultSettings}
                            </Button>}
                            {!this.props.isadd && <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.restoreSettings()}>
                                <Button size='large' type="primary" style={{ marginRight: 20 }} hidden={this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR}>
                                    {strings.restoredefaultsettings}
                                </Button>
                            </Popconfirm>}
                            {this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK && <Button size='large' style={{ marginRight: 20 }} onClick={this.props.backbtn} hidden={this.props.isadd}>
                                {strings.back}
                            </Button>}
                        </Col>
                    </Row>
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerBack) this.props.backbtn(); }}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
                <Modal
                    title={'QR Code'}
                    visible={this.state.showqrmodel}
                    onCancel={() => this.setState({ showqrmodel: false })}
                    footer={null}
                >
                    <div style={{ marginLeft: '23%' }}><QRCode value={"roomid=" + this.state.rid} size={256} /></div>
                </Modal>

            </div>
        );
    }
}

/* const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        fontFamily: 'msyh',
        height: 500,
    },
    section: {
        padding: 80,
        flexGrow: 1
    }
}); */
export default AddResource;

