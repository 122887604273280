import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col, Tooltip, Button,Spin,Modal,Select,DatePicker, Table, Tag, Icon,message } from "antd";
import "antd/dist/antd.css";
import moment from 'moment';
import {ApiHeader,strings,apiurl,RESOURCE_TYPES,getContactPerson} from './data';
const timelines = ['8AM','8:15AM','8:30AM','8:45AM',
                    '9AM','9:15AM','9:30AM','9:45AM','10AM','10:15AM','10:30AM','10:45AM','11AM','11:15AM','11:30AM','11:45AM',
                    '12PM','12:15PM','12:30PM','12:45PM','1PM','1:15PM','1:30PM','1:45PM','2PM','2:15PM','2:30PM','2:45PM',
                    '3PM','3:15PM','3:30PM','3:45PM','4PM','4:15PM','4:30PM','4:45PM','5PM','5:15PM','5:30PM','5:45PM',
                    '6PM','6:15PM','6:30PM','6:45PM','7PM','7:15PM','7:30PM','7:45PM','8PM','8:15PM','8:30PM','8:45PM',
                    '9PM','9:15PM','9:30PM','9:45PM','10PM'];
const timelines1 = ['08:00','08:15','08:30', '08:45',
                    '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
                    '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
                    '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45',
                    '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
                    '21:00','21:15', '21:30', '21:45','22:00'];
const locale = 'ZH';
const thds = ['8am-9am','9am-10am','10am-11am','11am-12pm','12pm-1pm','1pm-2pm','2pm-3pm','3pm-4pm','4pm-5pm','5pm-6pm','6pm-7pm','7pm-8pm','8pm-9pm','9pm-10pm'];

const {Option} = Select;

export default class ZoomTimeline extends React.Component {
    constructor(props) {
        super(props);
        this.columns=[{
            title: strings.date,
            dataIndex: 'date',
            key: 'date',
            width:'10%',
            sorter: (a, b) => a.date.localeCompare(b.date, 'en', { numeric: true }),
        },{
            title: strings.zoom,
            dataIndex: 'nameEN',
            key: 'nameEN',
            width:'20%',       
            render:(val, record) => (<p className='tablelongtext' style={{width:200}}>{val}</p>),         
            sorter: (a, b) => a.nameEN.localeCompare(b.nameEN, 'en', { numeric: true }),
        }, {
            title: strings.capacity,
            dataIndex: 'capacity',
            key: 'capacity',
            width:'10%',
            sorter: (a, b) => (""+a.capacity).localeCompare((""+b.capacity), 'en', { numeric: true }),
        }, {
            title: strings.availabletime,
            dataIndex: 'availableTime',
            key: 'availableTime',
            width:'35%',
        }, {
            title: <div>{strings.type} <Icon type="info-circle-o" style={{fontSize:15,fontWeight:'bold'}} onClick={this.showZoomInfo}/></div>,
            dataIndex: 'isCloud',
            key: 'isCloud',
            width:'15%',
            render:(val, record) => (
            <p className='tablelongtext' style={{width:200}}>
              {(record.resourceType == RESOURCE_TYPES.ZOOM_MEETING?strings.meeting:strings.webinar)+(val=='N'?strings.onprem:strings.publiccloud) }
            </p>),
        },{
            title: strings.action,
            dataIndex: 'action',
            key: 'action',
            width:'10%',
            render: (tags, record) => (
              <span>
                  <Tag color={'#455773'} style={{marginTop:5}} onClick={()=>this.viewbtnclick(record)}>{strings.reserve}</Tag> 
              </span>
            ),
        }]

        this.state = {
            monthOne: new Date().getMonth() + 1,
            monthTwo: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getMonth() + 1,
            monthThree: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getMonth() + 1,
            monthFour: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getMonth() + 1,
            monthFive: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getMonth() + 1,
            monthSix: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getMonth() + 1,
            monthSeven: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getMonth() + 1,
            dayOne: new Date().getDate(),
            dayTwo: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getDate(),
            dayThree: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getDate(),
            dayFour: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getDate(),
            dayFive: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getDate(),
            daySix: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getDate(),
            daySeven: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getDate(),
    
    
            d1: new Date().getDay(),
            d2: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getDay(),
            d3: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getDay(),
            d4: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getDay(),
            d5: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getDay(),
            d6: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getDay(),
            d7: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getDay(),
            firstday:this.props.details ? new Date(this.props.details._d) : new Date(),
            dateDetails: this.props.details ? new Date(this.props.details._d) : new Date(),
            dateDetails1: this.props.details ? new Date(this.props.details._d) : new Date(),
            showinfo:false,
            resources:[],
            equipment:[],
            booking:[],
            maintenance:[],
            binfo:null,
            rooms:[],
            trs:[],
            left:0,
            top:0,
            loading:false,
            triggerReload:false,
            showalertmodel:false,
            alertmessage:"",
            isfp:false,
            rtype:'all',
            isSearch:false,
            fromdate1:moment(),
            todate1:moment().add(5,'d'),
            fromdate:moment().format('YYYYMMDD'),
            todate:moment().add(5,'d').format('YYYYMMDD'),
            meetingtype:RESOURCE_TYPES.ZOOM_MEETING,
            duration:1,
            datevalue:null,
            loading1:false,
            zas:[],
            showZoomInfo:false,
        };
    }

    ontagclick=(b)=>{

    }

    showZoomInfo=()=>{
      this.setState({showZoomInfo:true});
    }

    onfromChange=(val)=>{
      this.setState({fromdate1:val,fromdate:val.format('YYYYMMDD')})
    }

    ontoChange=(val)=>{
        this.setState({todate1:val,todate:val.format('YYYYMMDD')})
    }

    componentDidMount() {
        var d = sessionStorage.getItem('systemdata');
        ApiHeader.userId = sessionStorage.getItem("userId");
        var sd = JSON.parse(d);
        var zoomAccounts = sd.zoomAccounts;
        this.setState({zoomAccounts:sd.zoomAccounts,systemData:sd});
        var rooms = [];
        for(var i=0; i<zoomAccounts.length; i++){    
          if(zoomAccounts[i].resourceType === RESOURCE_TYPES.ZOOM_MEETING)        
            rooms.push({name:strings.getLanguage()=='en'?zoomAccounts[i].nameEN:zoomAccounts[i].nameZH,id:zoomAccounts[i].id,capacity:zoomAccounts[i].capacity,iswebinar:false,isprem:zoomAccounts[i].isCloud=='N'?true:false});
        }
        rooms.sort((a, b) => a.name.localeCompare(b.name)); 
        var rooms1 = [];
        for(var i=0; i<zoomAccounts.length; i++){    
          if(zoomAccounts[i].resourceType === RESOURCE_TYPES.ZOOM_WEBINAR)        
            rooms1.push({name:strings.getLanguage()=='en'?zoomAccounts[i].nameEN:zoomAccounts[i].nameZH,id:zoomAccounts[i].id,capacity:zoomAccounts[i].capacity,iswebinar:true,isprem:zoomAccounts[i].isCloud=='N'?true:false});
        }
        
        rooms1.sort((a, b) => a.name.localeCompare(b.name)); 
        rooms = rooms.concat(rooms1);       
        this.setState({rooms:rooms});
    }
    
    
    componentWillUnmount() {

    }


    onChnageType=(e)=>{
      var rooms = [], zoomAccounts=this.state.zoomAccounts;
      if(e === 'all'){
        for(var i=0; i<zoomAccounts.length; i++){    
          if(zoomAccounts[i].resourceType === RESOURCE_TYPES.ZOOM_MEETING)        
            rooms.push({name:strings.getLanguage()=='en'?zoomAccounts[i].nameEN:zoomAccounts[i].nameZH,id:zoomAccounts[i].id,capacity:zoomAccounts[i].capacity,iswebinar:false,isprem:zoomAccounts[i].isCloud=='N'?true:false});
        }
        rooms.sort((a, b) => a.name.localeCompare(b.name)); 
        var rooms1 = [];
        for(var i=0; i<zoomAccounts.length; i++){    
          if(zoomAccounts[i].resourceType === RESOURCE_TYPES.ZOOM_WEBINAR)        
            rooms1.push({name:strings.getLanguage()=='en'?zoomAccounts[i].nameEN:zoomAccounts[i].nameZH,id:zoomAccounts[i].id,capacity:zoomAccounts[i].capacity,iswebinar:true,isprem:zoomAccounts[i].isCloud=='N'?true:false});
        }
        
        rooms1.sort((a, b) => a.name.localeCompare(b.name)); 
        rooms = rooms.concat(rooms1);
        this.setState({rtype:e,rooms:rooms});
        this.getBookingForDay(null,e,rooms)
      }else if(e === RESOURCE_TYPES.ZOOM_MEETING){
        for(var i=0; i<zoomAccounts.length; i++){    
          if(zoomAccounts[i].resourceType === RESOURCE_TYPES.ZOOM_MEETING)        
            rooms.push({name:strings.getLanguage()=='en'?zoomAccounts[i].nameEN:zoomAccounts[i].nameZH,id:zoomAccounts[i].id,capacity:zoomAccounts[i].capacity,iswebinar:false,isprem:zoomAccounts[i].isCloud=='N'?true:false});
        }
        rooms.sort((a, b) => a.name.localeCompare(b.name)); 
        this.setState({rtype:e,rooms:rooms});
        this.getBookingForDay(null,e,rooms)
      }else if(e === RESOURCE_TYPES.ZOOM_WEBINAR){
        for(var i=0; i<zoomAccounts.length; i++){    
          if(zoomAccounts[i].resourceType === RESOURCE_TYPES.ZOOM_WEBINAR)        
            rooms.push({name:strings.getLanguage()=='en'?zoomAccounts[i].nameEN:zoomAccounts[i].nameZH,id:zoomAccounts[i].id,capacity:zoomAccounts[i].capacity,iswebinar:true,isprem:zoomAccounts[i].isCloud=='N'?true:false});
        }
        
        rooms.sort((a, b) => a.name.localeCompare(b.name)); 
        this.setState({rtype:e,rooms:rooms});
        this.getBookingForDay1(null,[],rooms)
      }
      
    }

    getBookingForDay=(date,type,rooms)=>{
        var rdata = {};
        var d;
        if(date == null){
          d = this.state.dateDetails;
        }else{
          d = date;
        }

        if(type == null){
          type= 'all';
        }

        if(rooms == null){
          rooms = this.state.rooms;
        }
        
        var dval = d.getFullYear()+''+('0' + (d.getMonth() + 1)).slice(-2)+''+('0' + d.getDate()).slice(-2);
        rdata.fromDate = dval;
        rdata.toDate = dval;
        rdata.state = ['booked','pending_accept','confirmed']
        rdata.resourceType = RESOURCE_TYPES.ZOOM_MEETING;
          
        fetch(apiurl+'booking/list', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin',  
            body: JSON.stringify(rdata),         
          }).then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  throw new Error('Something went wrong ...');
                }
              })
              .then(data =>{
                if(data.code == "1"){
                  var bookings = [];
                  if(data.data.bookings.length>0){
                    bookings = data.data.bookings[0].booking;
                  }
                  if(type === 'all'){
                    this.getBookingForDay1(date,bookings,rooms)
                  }else{
                    this.rendertimelinebooking(bookings,rooms,dval)
                  }
                  
                  
                }else{
                  this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false}) 
          })
          .catch(e => {
            this.setState({loading:true});
            console.warn(e)
          })
    }

    getBookingForDay1=(date,bs,rooms)=>{
      var rdata = {};
      var d;
      if(date == null){
        d = this.state.dateDetails;
      }else{
        d = date;
      }

      if(rooms == null){
        rooms = this.state.rooms;
      }
      
      var dval = d.getFullYear()+''+('0' + (d.getMonth() + 1)).slice(-2)+''+('0' + d.getDate()).slice(-2);
      rdata.fromDate = dval;
      rdata.toDate = dval;
      rdata.state = ['booked','pending_accept','confirmed']
      rdata.resourceType = RESOURCE_TYPES.ZOOM_WEBINAR;
        
      fetch(apiurl+'booking/list', {
          headers: ApiHeader,
          method: 'POST', 
          credentials: 'same-origin',  
          body: JSON.stringify(rdata),         
        }).then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Something went wrong ...');
              }
            })
            .then(data =>{
              if(data.code == "1"){
                var bookings = [];
                if(data.data.bookings.length>0){
                  bookings = data.data.bookings[0].booking;
                }
                bookings = bookings.concat(bs);
                this.rendertimelinebooking(bookings,rooms,dval)
              }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
              }
              this.setState({loading:false}) 
        })
        .catch(e => {
          this.setState({loading:true});
          console.warn(e)
        })
    }

    rendertimelinebooking=(bookings,rooms,dval)=>{
      if(rooms == null){
        rooms = this.state.rooms;
      }
      var trs = [];
      for(var i=0;i<rooms.length; i++){
        
            var tr = {};
            tr.id = rooms[i].id;
            tr.rvalue = rooms[i].name.replace('Online ','') +"\n "+(rooms[i].isprem?strings.onprem:strings.publiccloud)+"\n("+strings.cap+rooms[i].capacity+")";
            var tds = [];
            var times = [];
            var bids = [];
            for(var b=0; b<bookings.length;b++){
                if(bookings[b].zoomAccountId == rooms[i].id){
                    bookings[b].room = rooms[i].name;
                    bookings[b].type = rooms[i].iswebinar?strings.zoomwebinar:strings.zoommeeting;
                    var ds = moment.unix((bookings[b].startTime/1000)).format("hh:mmA");
                    if(ds.startsWith('0')){
                        ds = ds.slice( 1 );
                    }
                    ds = ds.replace(':00','');
                    times.push(ds);
                    bids.push(bookings[b].id);
                    var st = bookings[b].startTime/1000;
                    var et = bookings[b].endTime/1000;
                    
                    while(st<(et-(15*60))){
                        st = st + (15*60);
                        var ds1 = moment.unix((st)).format("hh:mmA");
                        if(ds1.startsWith('0')){
                            ds1 = ds1.slice( 1 );
                        }
                        ds1 = ds1.replace(':00','');
                        times.push(ds1);
                        bids.push(bookings[b].id);
                    }
                }
            }
            var isstart = false;
            for(var j=0;j<timelines.length-1;j++){
              var datestr = dval+' '+timelines1[j]
              var cdate = moment(datestr,'YYYYMMDD HH:mm');
              if(cdate.valueOf()<=moment().valueOf() || ['9:15PM','9:30PM','9:45PM','9PM'].includes(timelines[j])){
                var td = {}
                td.disabled=true;
                td.isbooking=true;
                td.id=rooms[i].id+"-"+j+"-"+bids[index];
                td.timevalue=timelines[j];
                tds.push(td);
              }else{
                var index = times.indexOf(timelines[j]);
                if(index==-1){
                    var td = {};
                    td.disabled=false;
                    td.isbooking=true;
                    td.id=rooms[i].id+"-"+j+"-0";
                    td.timevalue=timelines[j];
                    tds.push(td);
                }else{
                    var td = {}
                    td.disabled=true;
                    td.isbooking=true;
                    td.id=rooms[i].id+"-"+j+"-"+bids[index];
                    td.timevalue=timelines[j];
                    tds.push(td);
                }
              }                           
            }
            tr.tds = tds;
            trs.push(tr);                        
      }
      this.setState({trs:trs,booking:bookings})
    }

    
    forward() {
        let dateDetails = this.state.firstday;
        this.setState({
            dateDetails: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)),
            dateDetails1: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)),
            firstday: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)),
            dayOne: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)).getDate(),
            dayTwo: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 8)).getDate(),
            dayThree: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 9)).getDate(),
            dayFour: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 10)).getDate(),
            dayFive: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 11)).getDate(),
            daySix: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 12)).getDate(),
            daySeven: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 13)).getDate(),

            monthOne: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)).getMonth() + 1,
            monthTwo: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 8)).getMonth() + 1,
            monthThree: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 9)).getMonth() + 1,
            monthFour: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 10)).getMonth() + 1,
            monthFive: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 11)).getMonth() + 1,
            monthSix: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 12)).getMonth() + 1,
            monthSeven: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 13)).getMonth() + 1,

            d1: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)).getDay(),
            d2: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 8)).getDay(),
            d3: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 9)).getDay(),
            d4: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 10)).getDay(),
            d5: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 11)).getDay(),
            d6: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 12)).getDay(),
            d7: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 13)).getDay(),

        })
        this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
        this.refs.d1.className = "TimeRangeBox1";
        var self = this;
        this.setState({loading:true});
        setTimeout(function(){
          self.getBookingForDay(null);
        }, 500);
        
    }

    backward() {
        let dateDetails = this.state.firstday;
        this.setState({
            dateDetails: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)),
            dateDetails1: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)),
            firstday: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)),
            dayOne: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)).getDate(),
            dayTwo: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 6)).getDate(),
            dayThree: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 5)).getDate(),
            dayFour: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 4)).getDate(),
            dayFive: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 3)).getDate(),
            daySix: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 2)).getDate(),
            daySeven: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 1)).getDate(),

            monthOne: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)).getMonth() + 1,
            monthTwo: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 6)).getMonth() + 1,
            monthThree: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 5)).getMonth() + 1,
            monthFour: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 4)).getMonth() + 1,
            monthFive: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 3)).getMonth() + 1,
            monthSix: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 2)).getMonth() + 1,
            monthSeven: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 1)).getMonth() + 1,

            d1: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)).getDay(),
            d2: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 6)).getDay(),
            d3: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 5)).getDay(),
            d4: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 4)).getDay(),
            d5: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 3)).getDay(),
            d6: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 2)).getDay(),
            d7: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 1)).getDay(),

        })
        this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
        this.refs.d1.className = "TimeRangeBox1";
        var self = this;
        this.setState({loading:true});
        setTimeout(function(){
          self.getBookingForDay(null);
        }, 500);
    }

    today() {
        this.setState({
            monthOne: new Date().getMonth() + 1,
            monthTwo: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getMonth() + 1,
            monthThree: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getMonth() + 1,
            monthFour: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getMonth() + 1,
            monthFive: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getMonth() + 1,
            monthSix: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getMonth() + 1,
            monthSeven: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getMonth() + 1,
            dayOne: new Date().getDate(),
            dayTwo: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getDate(),
            dayThree: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getDate(),
            dayFour: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getDate(),
            dayFive: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getDate(),
            daySix: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getDate(),
            daySeven: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getDate(),


            d1: new Date().getDay(),
            d2: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getDay(),
            d3: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getDay(),
            d4: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getDay(),
            d5: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getDay(),
            d6: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getDay(),
            d7: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getDay(),

            dateDetails: new Date(),
            dateDetails1: new Date()
        })
        this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
        this.refs.d1.className = "TimeRangeBox1";
        var self = this;
        this.setState({loading:true});
        setTimeout(function(){
          self.getBookingForDay(null);
        }, 500);
    }


    componentWillReceiveProps = (nextProps) => {
        if(nextProps.changeDay==false){
          return;
        }
        let dateDetails = nextProps.details ? nextProps.details._d : new Date();
        var cd = nextProps.datevalue.clone();
        var cd1 = nextProps.datevalue;
        this.setState({datevalue:nextProps.datevalue,fromdate1:cd,fromdate:cd.format('YYYYMMDD'),
        todate1:cd1,todate:cd1.format('YYYYMMDD')})
        if(this.state.isfp != nextProps.changeDay){
          this.setState({isfp:nextProps.changeDay})
        }
        
        this.setState({
            dateDetails: dateDetails,
            dateDetails1: dateDetails,
            dayOne: new Date(dateDetails.getTime()).getDate(),
            dayTwo: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 1)).getDate(),
            dayThree: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 2)).getDate(),
            dayFour: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 3)).getDate(),
            dayFive: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 4)).getDate(),
            daySix: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 5)).getDate(),
            daySeven: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 6)).getDate(),

            monthOne: dateDetails.getMonth() + 1,
            monthTwo: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 1)).getMonth() + 1,
            monthThree: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 2)).getMonth() + 1,
            monthFour: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 3)).getMonth() + 1,
            monthFive: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 4)).getMonth() + 1,
            monthSix: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 5)).getMonth() + 1,
            monthSeven: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 6)).getMonth() + 1,


            d1: dateDetails.getDay(),
            d2: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 1)).getDay(),
            d3: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 2)).getDay(),
            d4: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 3)).getDay(),
            d5: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 4)).getDay(),
            d6: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 5)).getDay(),
            d7: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 6)).getDay(),
            firstday:dateDetails,
        })
        this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
        this.refs.d1.className = "TimeRangeBox1";
        var self = this;
        this.setState({loading:true});
        setTimeout(function(){
          self.getBookingForDay(null);
        }, 500);
    }

    changeday=(day)=>{
      this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
      if(day === 0){
        this.refs.d1.className="TimeRangeBox1";
      }else if(day === 1){
        this.refs.d2.className="TimeRangeBox1";
      }else if(day === 2){
        this.refs.d3.className="TimeRangeBox1";
      }else if(day === 3){
        this.refs.d4.className="TimeRangeBox1";
      }else if(day === 4){
        this.refs.d5.className="TimeRangeBox1";
      }else if(day === 5){
        this.refs.d6.className="TimeRangeBox1";
      }else if(day === 6){
        this.refs.d7.className="TimeRangeBox1";
      }
      var d = this.state.dateDetails1;      
      var d1 = new Date(d.getTime() + day*24*60*60*1000);      
      this.setState({loading:true,dateDetails:d1});        
      this.getBookingForDay(d1);
        
    }

    
    
      renderTr=()=>{
        var rds = [];    
        this.state.trs.map((d) => {    
            rds.push(<tr key={d.id} className='tablerowbz'>{this.renderTd(d.rvalue,d.tds)}</tr>);
        });
        return rds;
      }
    
      renderHeaderTr=()=>{
        var rds = [];       
        rds.push(<tr key='999' >{this.renderheaderTd()}</tr>);
        return rds;
      }
    
      renderheaderTd=()=>{
        var rhds = [];
        thds.map((d,index) => {
          rhds.push(<td key={index} className='tablerow2tdb'>{d}</td>);
        });
        return rhds;
      }
    
      renderRespurce=()=>{
        var res = [];
        res.push(<tr key='999' className='tablerow1bz'><td className='roomnamecolb' style={{textAlign:'center'}}><div className="tddiv">{strings.type} <Icon onClick={this.showZoomInfo} type="info-circle-o" style={{fontSize:15,fontWeight:'bold'}}/></div></td></tr>);
        this.state.trs.map((d)=>{
            res.push(<tr key={d.id} className='tablerow1bz' ><td>{d.rvalue.split("\n").map((i,key) => {return <div key={key}>{i}</div>})}</td></tr>);
        })
        return res;
      }
    
      renderTd=(rval,tds)=>{
        var rds = [];
        tds.map((d) => {    
            rds.push(
              d.isbooking?
                d.disabled?
                  <td key={d.id} className='disabledtd' onMouseOver={(e)=>this.onhover(e,d.id,true)} onMouseOut={(e)=>this.onhover1(e)} ></td>
                  :<td key={d.id} onClick={()=>this.bktdclick(d.id,d.timevalue,rval,this.state.dateDetails,tds)}></td>:
                d.disabled?
                  <td key={d.id} className='disabledtd1' onMouseOver={(e)=>this.onhover(e,d.id,false)} onMouseOut={(e)=>this.onhover1(e)} ></td>
                  :<td key={d.id} onClick={()=>this.bktdclick(d.id,d.timevalue,rval,this.state.dateDetails,tds)}></td>  
              );
        });
        return rds;
      }
    
      bktdclick=(id,timevalue,rval,datedetails,tds)=>{
        this.props.tdclick(id,timevalue,rval,datedetails,tds);
      }


      viewbtnclick=(b)=>{
        this.props.tdclick(b.id+"-0-0","","",new Date(b.date));
      }

      onhover2=(e)=>{
        e.persist()
        //console.log(e);
      }

      onhover=(e,id,isbooking)=>{
        e.persist()
        var scrolldiv = document.getElementById('scrolldivtimelinezoom');
        let localX = e.clientX - scrolldiv.scrollLeft;
        let localY = e.clientY + scrolldiv.scrollTop;
        if(scrolldiv.offsetHeight < e.clientY+200){
          localY = localY - 520;
        }else{
          localY = localY - 210;
        }
        this.setState({showinfo:true, top:localY,left:localX-900});
        if(isbooking){
          var bs = this.state.booking;
          for(var i=0; i<bs.length; i++){
            if(bs[i].id+'' === id.split("-")[2]){
              var binfo = {};
              binfo.room=bs[i].room;
              binfo.type=bs[i].type;
              binfo.date= moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD") ;
              binfo.time=moment.unix((bs[i].startTime/1000)).format("hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
              binfo.subject=bs[i].subject;
              binfo.creator=bs[i].userName;
              binfo.isbooking=true;
              binfo.remark=bs[i].remark;
              binfo.contact1=bs[i].contactPerson1;
              binfo.contact2=bs[i].contactPerson2;
              binfo.salutation1=bs[i].salutation1;  
              binfo.salutation2=bs[i].salutation2; 
              binfo.contact3=bs[i].contactPhone1;
              binfo.contact4=bs[i].contactPhone2;
              this.setState({binfo:binfo});
              break;            
            }
          }
        }else{
          var bs = this.state.maintenance;
          for(var i=0; i<bs.length; i++){
            if(bs[i].id+'' === id.split("-")[2]){
              var binfo = {};
              binfo.room=bs[i].room;
              binfo.isbooking=false;
              binfo.starttime = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA");
              binfo.endtime = moment.unix((bs[i].endTime/1000)).format("YYYY-MM-DD hh:mmA");
              binfo.description=bs[i].description;
              this.setState({binfo:binfo});
              break;            
            }
          }
        }
        
      }

      onhover1=(e)=>{
        e.persist()
        this.setState({showinfo:false,binfo:null});
      }

      findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
      }

      searchBooking=()=>{
        var rdata={};
        if(parseInt(this.state.fromdate)>parseInt(this.state.todate)){
          message.warn(strings.errorcodes.B021);
          return;
        }
        rdata.from = this.state.fromdate;
        rdata.to = this.state.todate;
        rdata.type = this.state.meetingtype;
        rdata.duration = this.state.duration;
        this.setState({loading1:true})
        fetch(apiurl+'zoom/availablelist', {
          headers: ApiHeader,
          method: 'POST', 
          credentials: 'same-origin',  
          body: JSON.stringify(rdata),         
      }).then(response => {
              if (response.ok) {
              return response.json();
              } else {
              throw new Error('Something went wrong ...');
              }
          })
          .then(data =>{
              if(data.code == "1"){
                var zas = data.data.zoomAccounts;
                this.setState({zas:zas})
              }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
              }
              this.setState({loading1:false})
          })
          .catch(e => {console.warn(e);this.setState({loading1:false});})
      }

      clearScrren=()=>{
        this.setState({
          fromdate1:this.state.datevalue,
          fromdate:this.state.datevalue.format('YYYYMMDD'),
          todate1:this.state.datevalue,
          todate:this.state.datevalue.format('YYYYMMDD'),
          meetingtype:RESOURCE_TYPES.ZOOM_MEETING,
          duration:1,
        })        
      }

    render() {
        let daysArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        let monthsArray = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; const { dateDetails, dayOne, dayTwo, dayThree, dayFour, dayFive, daySeven, daySix, monthFive, monthFour, monthOne, monthSeven, monthSix, monthThree,
            monthTwo, d1, d3, d2, d4, d5, d6, d7 } = this.state
        let monthStr = "";
        (monthsArray[monthOne] !== monthsArray[monthSeven]) ?
            monthStr = monthsArray[monthOne] + " - " + monthsArray[monthSeven] + " " + dateDetails.getFullYear()
            : monthStr = monthsArray[monthOne] + " " + dateDetails.getFullYear()



        return (
            <div className="SecondBox" style={{marginTop:0,backgroundColor:'rgba(0,0,0,0)'}} >
              
              {!this.state.isSearch && <Row span={24} style={{ marginBottom: 15 }}>
                    <Col span={6}>
                        <Select value={this.state.rtype} onChange={(e)=>this.onChnageType(e)} className='setupinputbox' style={{float: 'right',marginTop:6,width:150 }} >
                            <Option value="all">{strings.all}</Option>
                            <Option value={RESOURCE_TYPES.ZOOM_MEETING}>{strings.meeting}</Option>
                            <Option value={RESOURCE_TYPES.ZOOM_WEBINAR}>{strings.webinar}</Option>
                        </Select>
                    </Col>
                    <Col span={6}>
                        <span style={{ fontSize: "1.4rem", fontWeight: "bold", float: 'right',marginTop:6 }}>{monthStr}</span>
                    </Col>
                    <Col span={8} style={{width:'36.5%'}}>
                        <Button onClick={this.today.bind(this)} size='large' style={{marginLeft:15,marginTop:8}} >{strings.today}</Button>
                    </Col>
                    <Col span={3}>
                        <Button onClick={()=>this.setState({isSearch:true})} size='large' style={{float: 'right',marginTop:8}} >{strings.advancesearch}</Button>
                    </Col>
              </Row> } 
              {<Row lg={24} hidden={this.state.isSearch}>
                    <Col lg={3} className='firstbox'>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',alignItems:'center',textAlign:'center',height:'100%',cursor:'pointer'}}>
                        <span className='prevnextbtn' onClick={this.backward.bind(this)}>
                          {'< '+strings.previous}
                        </span>
                        <span className='prevnextbtn' style={{borderLeftWidth:0}} onClick={this.forward.bind(this)}>
                          {strings.next+' >'}
                        </span>
                      </div>                        
                    </Col>                     
                    <Col lg={3} className='dayboxs'>
                        <div className="TimeRangeBox1" ref="d1" style={{borderLeft:'0.5px solid white'}} onClick={()=>this.changeday(0)}>
                            <span style={{ color: '#405069', fontWeight: '500'}}>{monthOne < 10 ? "0" + monthOne : monthOne}-{dayOne < 10 ? "0" + dayOne : dayOne}<span style={{ color: '#ff7f27' }}>({daysArray[d1]})</span></span>
                        </div>
                    </Col>
                    <Col lg={3} className='dayboxs'>
                        <div className="TimeRangeBox" ref="d2" onClick={()=>this.changeday(1)}>
                            <span style={{ color: '#405069', fontWeight: '500', }}>{monthTwo < 10 ? "0" + monthTwo : monthTwo}-{dayTwo < 10 ? "0" + dayTwo : dayTwo}<span style={{ color: '#ff7f27' }}>({daysArray[d2]})</span></span>

                        </div>
                    </Col>
                    <Col lg={3} className='dayboxs'>
                        <div className="TimeRangeBox" ref="d3" onClick={()=>this.changeday(2)}>
                            <span style={{ color: '#405069', fontWeight: '500', }}>{monthThree < 10 ? "0" + monthThree : monthThree}-{dayThree < 10 ? "0" + dayThree : dayThree}<span style={{ color: '#ff7f27' }}>({daysArray[d3]})</span></span>
                        </div>
                    </Col>
                    <Col lg={3} className='dayboxs'>
                        <div className="TimeRangeBox" ref="d4" onClick={()=>this.changeday(3)}>
                            <span style={{ color: '#405069', fontWeight: '500', }}>{monthFour < 10 ? "0" + monthFour : monthFour}-{dayFour < 10 ? "0" + dayFour : dayFour}<span style={{ color: '#ff7f27' }}>({daysArray[d4]})</span></span>

                        </div>
                    </Col>
                    <Col lg={3} className='dayboxs'>
                        <div className="TimeRangeBox" ref="d5" onClick={()=>this.changeday(4)}>
                            <span style={{ color: '#405069', fontWeight: '500', }}>{monthFive < 10 ? "0" + monthFive : monthFive}-{dayFive < 10 ? "0" + dayFive : dayFive}<span style={{ color: '#ff7f27' }}>({daysArray[d5]})</span></span>

                        </div>
                    </Col>
                    <Col lg={3} className='dayboxs'>
                        <div className="TimeRangeBox" ref="d6" onClick={()=>this.changeday(5)}>
                            <span style={{ color: '#405069', fontWeight: '500', }}>{monthSix < 10 ? "0" + monthSix : monthSix}-{daySix < 10 ? "0" + daySix : daySix}<span style={{ color: '#ff7f27' }}>({daysArray[d6]})</span></span>
                        </div>
                    </Col>
                    <Col lg={3} className='dayboxs'>
                        <div className="TimeRangeBox" ref="d7" onClick={()=>this.changeday(6)}>
                            <span style={{ color: '#405069', fontWeight: '500' }}>{monthSeven < 10 ? "0" + monthSeven : monthSeven}-{daySeven < 10 ? "0" + daySeven : daySeven}<span style={{ color: '#ff7f27' }}>({daysArray[d7]})</span></span>
                        </div>
                    </Col>
                </Row>}
                {!this.state.isSearch && <Spin spinning={this.state.loading} >
                <Row lg={24} style={{height:600}}>
                    <Col lg={3} className='dayboxs1'>
                      <div style={{marginTop:0,backgroundColor:'#fff',}}>
                        <table>
                          <tbody>
                            {this.renderRespurce()}                
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    <Col lg={18} className='timeslot-maindivb'> 
                      <table>
                        <tbody>
                          {this.renderHeaderTr()}
                        </tbody>
                      </table>
                      <table style={{marginTop:-1}}>
                        <tbody>
                          {this.renderTr()}                
                        </tbody>
                      </table>
                      {this.state.binfo !== null && this.state.binfo.room != null && <div className='bookingInfodiv' style={{top:this.state.top,left:this.state.left}} hidden={!this.state.showinfo}>
                        <Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.type}:</span></Col>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{this.state.binfo == null?'N/A':this.state.binfo.type}</span></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.account}:</span></Col>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{this.state.binfo == null?'N/A':this.state.binfo.room}</span></Col>
                        </Row>
                        {this.state.binfo != null && this.state.binfo.isbooking==true?<Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.date}:</span></Col>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{this.state.binfo == null?'N/A':this.state.binfo.date}</span></Col>
                        </Row>:<Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.starttime}:</span></Col>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{this.state.binfo == null?'N/A':this.state.binfo.starttime.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm')}</span></Col>
                        </Row>}
                         {this.state.binfo != null && this.state.binfo.isbooking==true?<Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.time}:</span></Col>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{this.state.binfo == null?'N/A':this.state.binfo.time.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm')}</span></Col>
                        </Row>:<Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.endtime}:</span></Col>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{this.state.binfo == null?'N/A':this.state.binfo.endtime.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm')}</span></Col>
                        </Row>}
                        {this.state.binfo != null && this.state.binfo.isbooking==true?<Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.subject}:</span></Col>
                          <Col span={12}><p style={{fontSize:14,fontWeight:450,margin:0}} className='bookinginfotext'>{this.state.binfo == null?'N/A':this.state.binfo.subject}</p></Col>
                        </Row>:''}
                        {this.state.binfo != null && this.state.binfo.isbooking==true?<Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.creator}:</span></Col>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{this.state.binfo == null?'N/A':this.state.binfo.creator}</span></Col>
                        </Row>:''}
                        {this.state.binfo != null && this.state.binfo.isbooking==true?<Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.remark}:</span></Col>
                          <Col span={12}><p style={{fontSize:14,fontWeight:450,margin:0}} className='bookinginfotext'>{this.state.binfo == null?'N/A':this.state.binfo.remark}</p></Col>
                        </Row>:''}
                        {this.state.binfo != null && this.state.binfo.isbooking==true?<Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.contact1}:</span></Col>
                          <Col span={12}><p style={{fontSize:14,fontWeight:450,margin:0}} className='bookinginfotext'>{this.state.binfo == null?'N/A':getContactPerson(strings, this.state.binfo.salutation1, this.state.binfo.contact1)}</p></Col>
                        </Row>:''}
                        {this.state.binfo != null && this.state.binfo.isbooking==true?<Row type="flex" style={{marginTop:5,marginLeft:10,marginBottom:5}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.contact3}:</span></Col>
                          <Col span={12}><p style={{fontSize:14,fontWeight:450,margin:0}} className='bookinginfotext'>{this.state.binfo == null?'N/A':this.state.binfo.contact3}</p></Col>
                        </Row>:''}
                        {this.state.binfo != null && this.state.binfo.isbooking==true?<Row type="flex" style={{marginTop:5,marginLeft:10}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.contact2}:</span></Col>
                          <Col span={12}><p style={{fontSize:14,fontWeight:450,margin:0}} className='bookinginfotext'>{this.state.binfo == null?'N/A':getContactPerson(strings, this.state.binfo.salutation2, this.state.binfo.contact2)}</p></Col>
                        </Row>:''}
                        {this.state.binfo != null && this.state.binfo.isbooking==true?<Row type="flex" style={{marginTop:5,marginLeft:10,marginBottom:5}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.contact4}:</span></Col>
                          <Col span={12}><p style={{fontSize:14,fontWeight:450,margin:0}} className='bookinginfotext'>{this.state.binfo == null?'N/A':this.state.binfo.contact4}</p></Col>
                        </Row>:''}
                        {this.state.binfo != null && this.state.binfo.isbooking==false?<Row type="flex" style={{marginTop:5,marginLeft:10,marginBottom:5}}>
                          <Col span={12}><span style={{fontSize:14,fontWeight:450}}>{strings.description}:</span></Col>
                          <Col span={12}><p style={{fontSize:14,fontWeight:450,margin:0}} className='bookinginfotext'>{this.state.binfo == null?'N/A':this.state.binfo.description}</p></Col>
                        </Row>:''}
                      </div>}
                      
                    </Col>
                </Row></Spin>}
                {this.state.isSearch && <div>
                  <Row>
                    <Col span={21}></Col>
                    <Col span={2}>
                      <Button onClick={()=>this.setState({isSearch:false})} size='large' style={{float: 'right',marginLeft:15}}>{strings.basicsearch}</Button>
                    </Col>
                  </Row>
                    <div className="RoomInformationDiv" style={{marginTop:20,padding:10,width:'100%',marginLeft:0}}>
                        <span className="floorText2">{strings.searchavailabletimeslot}</span>
                        <Row xs={24}>
                            <Col xs={6}>
                              <div>
                                  <span className="placeholder">{strings.type}</span>
                              </div>
                              <Select className="InputSizeOfThird" value={this.state.meetingtype} style={{width:260}} onChange={e=>this.setState({meetingtype:e})}>
                                  <Option value={RESOURCE_TYPES.ZOOM_MEETING}>{strings.zoommeeting}</Option>
                                  <Option value={RESOURCE_TYPES.ZOOM_WEBINAR}>{strings.zoomwebinar}</Option>
                              </Select>
                            </Col>
                            <Col xs={6}>
                              <div>
                                <span className="placeholder">{strings.from}</span>
                              </div>
                              <DatePicker className="InputSizeOfThird" value={this.state.fromdate1} disabledDate={(current)=> current && current < moment(moment().format('YYYY-MM-DD'), "YYYY-MM-DD")} onChange={this.onfromChange} />
                                
                            </Col>
                            <Col xs={6}>    
                              <div>
                                  <span className="placeholder">{strings.to}</span>
                              </div>
                              <DatePicker className="InputSizeOfThird" value={this.state.todate1} disabledDate={(current)=> current && current < moment(moment().format('YYYY-MM-DD'), "YYYY-MM-DD")} onChange={this.ontoChange} /> 
                            </Col>
                            <Col xs={6}>
                              <div>
                                  <span className="placeholder">{strings.duration}</span>
                              </div>
                              <Select className="InputSizeOfThird" value={this.state.duration} style={{width:260}} onChange={e=>this.setState({duration:e})}>
                                  <Option value={0.5}>{strings.halfhour}</Option>
                                  <Option value={1}>{strings.onehour}</Option>
                                  <Option value={1.5}>{strings.onehalfhour}</Option>
                                  <Option value={2}>{strings.twohour}</Option>
                                  <Option value={2.5}>{strings.twohalfhour}</Option>
                                  <Option value={3}>{strings.threehour}</Option>
                                  <Option value={3.5}>{strings.threehalfhour}</Option>
                                  <Option value={4}>{strings.fourhour}</Option>
                                  <Option value={4.5}>{strings.fourhalfhour}</Option>
                                  <Option value={5}>{strings.fivehour}</Option>
                              </Select>     
                            </Col>
                        </Row>
                        <Button type="primary" size='large' style={{marginRight: 17,marginTop:20}}  loading={this.state.loading1} onClick={this.searchBooking}>
                            {strings.search}
                        </Button>
                        <Button size='large' style={{marginRight: 20,marginTop:20}}  onClick={this.clearScrren}>
                            {strings.clear}
                        </Button>
                    </div>
                    <Table columns={this.columns} dataSource={this.state.zas} pagination={{pageSize:10}} style={{marginTop:10}}/>
                    <div className="CountOfData">
                        <span className="CountText">{strings.totalcount} {this.state.zas.length}</span>
                    </div> 
                </div>
                }
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={()=>this.setState({showalertmodel:false})}
                    footer={[                  
                        <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                        {strings.ok}
                        </Button>,
                    ]}
                    >
                        <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                        <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                </Modal>
                <Modal
                    title={strings.info}
                    width={'48%'}
                    visible={this.state.showZoomInfo}
                    onCancel={()=>this.setState({showZoomInfo:false})}
                    footer={null}
                    >
                        <span className="TitleOfTheBox1">{strings.zoominfo1}</span><br/>
                        <span className="TitleOfTheBox1">{strings.zoominfo2}</span><br/>
                        <span className="TitleOfTheBox1">{strings.zoominfo3} {<a href="https://blog.zoom.us/when-to-use-meetings-vs-webinars/" target='_blank'>{strings.this}</a>}.</span>
                </Modal>
              </div> 

        );
    }
}
