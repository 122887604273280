import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col, Table, Select, Tag, Button, Radio, Modal, Popconfirm, Input, DatePicker, message, Tabs, Empty, Spin } from "antd";
import './App.css';
import './analystdata.css';
import moment from 'moment';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES, TYPES, SIZES, ZONES, DIVISIONS } from './data';
const Option = Select.Option;
const locale = 'EN';
const { TabPane } = Tabs;

const HOURS = ['', '8am-9am', '9am-10am', '10am-11am', '11am-12pm', '12pm-1pm', '1pm-2pm', '2pm-3pm', '3pm-4pm', '4pm-5pm', '5pm-6pm', '6pm-7pm', '7pm-8pm', '8pm-9pm'];

class AnalystData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            resource: null,
            loading: false,
            radiooption: 'a',
            showalertmodel: false,
            alertmessage: "",
            floors: [],
            floorIds: [],
            fromdate1: moment().subtract(1, 'months'),
            todate1: moment().subtract(1,'day'),
            divisions: ['all'],
            types: ['all'],
            classes: ['all'],
            zones: ['all'],
            sharing: ['all'],
            floor3f: {},
            graphdata: [],
            resourceData:[],
            changedData:[],
            selectedTab:'0',
        }
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber', 5);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, systemData: sd });
        var buildingFloor = sd.buildingFloor;
        var floors = [], floor3f = {};
        for (var i = 0; i < buildingFloor.length; i++) {
            if (buildingFloor[i].floorEN === '3/F' || buildingFloor[i].floorEN === '4/F' || buildingFloor[i].floorEN === '5/F' ||
                buildingFloor[i].floorEN === 'G/F' || buildingFloor[i].floorEN === '1/F' || buildingFloor[i].floorEN === '2/F') {
                if (locale === 'EN') {
                    floors.push({ id: buildingFloor[i].id, name: buildingFloor[i].floorEN, name1: buildingFloor[i].floorEN });
                } else {
                    floors.push({ id: buildingFloor[i].id, name: buildingFloor[i].floorZH, name1: buildingFloor[i].floorEN });
                }
            }

            if (buildingFloor[i].floorEN === '3/F') {
                floor3f = buildingFloor[i];
            }
        }
        this.setState({ floors: floors, floor3f: floor3f, floorIds: [floor3f.id] });
    }

    componentWillUnmount() {

    }

    searchData = () => {
        var rdata = {};
        if (this.state.floorIds.length>0) {
            rdata.floorIds = this.state.floorIds;
        } else {
            message.warning(strings.plsfillalletc);
            return;
        }

        if (this.state.divisions.length === 0 || this.state.zones.length === 0 || this.state.types.length === 0 || this.state.classes.length === 0 || this.state.sharing.length === 0) {
            message.warning(strings.plsfillalletc);
            return;
        } else {
            rdata.divisions = this.state.divisions;
            rdata.zones = this.state.zones;
            rdata.types = this.state.types;
            rdata.classes = this.state.classes;
            rdata.sharing = this.state.sharing;
        }

        if (this.state.fromdate1 !== null && this.state.todate1 !== null) {
            if(this.state.todate1.diff(this.state.fromdate1, 'months', true) > 1){
                message.warning(strings.daterangemonthserr1);
                return;                
            }else if(this.state.todate1.valueOf() < this.state.fromdate1.valueOf()){
                message.warning(strings.fromtoerr);
                return;                
            }else{                
                rdata.fromdate = this.state.fromdate1.valueOf();
                rdata.todate = this.state.todate1.valueOf();
            }            
        } else {
            message.warning(strings.plsfillalletc);
            return;
        }

        
        this.setState({ loading: true })

        fetch(apiurl + 'resource/getAnalystData', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                var data1 = data.data;
                this.setState({ graphdata: data1.resourceData })
                if(data1.resourceData.length>0){
                    this.getResourceData(data1.resourceData[0].id);
                }else{
                    this.setState({ loading: false })
                }
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true,  loading: false })
            }
            
        })
        .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    getResourceData=(rid)=>{
        var rdata = {};       

        if (this.state.fromdate1 !== null && this.state.todate1 !== null) {
            if(this.state.todate1.diff(this.state.fromdate1, 'months', true) > 1){
                message.warning(strings.daterangemonthserr1);
                return;                
            }else if(this.state.todate1.valueOf() < this.state.fromdate1.valueOf()){
                message.warning(strings.fromtoerr);
                return;                
            }else{                
                rdata.fromdate = this.state.fromdate1.valueOf();
                rdata.todate = this.state.todate1.valueOf();
            }            
        } else {
            message.warning(strings.plsfillalletc);
            return;
        }

        rdata.rid = rid;
        this.setState({ loading: true, resourceData:[] })

        fetch(apiurl + 'resource/getAnalystDataByResource', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                var data1 = data.data;
                //console.log(data1.resourceData);
                this.setState({ resourceData: data1.resourceData })
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    onfromChange = (val) => {
        this.setState({ fromdate1: val })
    }

    ontoChange = (val) => {
        this.setState({ todate1: val })
    }

    clearScrren = () => {
        this.setState({
            floorIds: [this.state.floor3f.id],
            fromdate1: moment().subtract(2, 'months'),
            todate1: moment(),
            divisions: ['all'],
            types: ['all'],
            classes: ['all'],
            zones: ['all'],
            sharing: ['all'],
            graphdata:[]
        })
    }

    onChangeZone = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ zones: ['all'] })
        } else {
            this.setState({ zones: e.filter((z) => z != 'all') })
        }
    }

    onChangeSharing = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ sharing: ['all'] })
        } else {
            this.setState({ sharing: e.filter((z) => z != 'all') })
        }
    }

    onChangeDivision = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ divisions: ['all'] })
        } else {
            this.setState({ divisions: e.filter((z) => z != 'all') })
        }
    }

    onChangeType = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ types: ['all'] })
        } else {
            this.setState({ types: e.filter((z) => z != 'all') })
        }
    }

    onChangeClass = (e) => {
        if (e[e.length - 1] === 'all') {
            this.setState({ classes: ['all'] })
        } else {
            this.setState({ classes: e.filter((z) => z != 'all') })
        }
    }

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    onTabChange=(e)=>{
        if(this.state.changedData.length>0){
            Modal.confirm({
                title: strings.confirm,
                content: strings.confirmchangetab,
                okText: strings.confirm,
                cancelText: strings.cancel,
                onOk:()=>this.changetab(e)
            }); 
        }else{
            this.changetab(e);
        }
               
    }

    changetab=(e)=>{
        this.setState({selectedTab:e, changedData:[]})
        this.getResourceData(this.state.graphdata[e].id);
    }

    onChangeValue=(i,j)=>{
        var data = this.state.resourceData;
        var value = 0;
        //console.log(this.state.changedData);
        //console.log(data[i].data[j]);
        if(this.state.changedData.filter((cd)=>cd.id === data[i].data[j].id).length === 0){
            if(data[i].data[j].clientOverridden === null){
                if(data[i].data[j].occupied === 0){
                    data[i].data[j].clientOverridden = 1;
                    value = 1;
                }else{
                    data[i].data[j].clientOverridden = 0;
                }  
            }else{
                if(data[i].data[j].clientOverridden === 0){
                    data[i].data[j].clientOverridden = null;
                    value = 1;
                }else{
                    data[i].data[j].clientOverridden = null;
                } 
            }
            //console.log(data[i].data[j]);
            this.setState({resourceData:data, changedData:[...this.state.changedData, {id:data[i].data[j].id, value:value, timeslots:data[i].data[j].timeslots, ids:data[i].data[j].ids }]});
        }else{            
            if(data[i].data[j].clientOverridden === null){
                if(data[i].data[j].occupied === 0){
                    data[i].data[j].clientOverridden = 1;
                    value = 1;
                }else{
                    data[i].data[j].clientOverridden = 0;
                }  
            }else{
                if(data[i].data[j].clientOverridden === 0){
                    data[i].data[j].clientOverridden = null;
                    value = 1;
                }else{
                    data[i].data[j].clientOverridden = null;
                } 
            }
            this.setState({resourceData:data, changedData:[...this.state.changedData.filter((cd)=>cd.id !== data[i].data[j].id)]});
        }        
    }

    resetData=()=>{
        this.setState({changedData:[]})
        this.getResourceData(this.state.graphdata[this.state.selectedTab].id);
    }

    saveData=()=>{
        var rdata = {};
        rdata.data = this.state.changedData;
        this.setState({ loading: true})

        fetch(apiurl + 'resource/saveAnalystDataByResource', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                message.success(strings.savedsuccess)
                this.setState({changedData:[]})
                this.getResourceData(this.state.graphdata[this.state.selectedTab].id);
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, loading: false })
            }
        })
        .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    getColor=(d)=>{
        if(d.clientOverridden !== null){
            if(d.clientOverridden === 1){
                return '#FE807E';
            }else{
                return '#9CD87F';
            }
        }else if(d.occupied !== null){
            if(d.occupied === 1){
                return '#FE807E';
            }else{
                return '#9CD87F';
            }
        }else{
            return '#DEDEDE';
        }
        
    }

    render() {
        return (
            <div>
                <div className="FirstDiv" style={{ marginTop: 10 }}>
                    <span className="TitleOfTheBox">{strings.selectioncriteria}</span>
                    <Row xs={24} >
                        <Col md={5}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.floor}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' value={this.state.floorIds} style={{ width: 260 }} onChange={(e) => this.setState({ floorIds: e })}>
                                {this.state.floors.map((f, i) => <Option key={i} value={f.id}>{f.name}</Option>)}
                            </Select>
                        </Col>
                        <Col md={5}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.from}</span>
                                <div style={{ marginBottom: 16 }}>
                                    <DatePicker className="InputSizeOfThird" style={{ width: 260 }} value={this.state.fromdate1} onChange={this.onfromChange} />
                                </div>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.to}</span>
                            </div>
                            <DatePicker className="InputSizeOfThird" style={{ width: 260 }} value={this.state.todate1} onChange={this.ontoChange} />
                        </Col>
                        <Col md={5}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.zone}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' style={{ width: 260 }} value={this.state.zones} onChange={(e) => this.onChangeZone(e)}>
                                <Option value={'all'}>{strings.all}</Option>
                                {ZONES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                            </Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.division}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' style={{ width: 260 }} value={this.state.divisions} onChange={(e) => this.onChangeDivision(e)}>
                                <Option value={'all'}>{strings.all}</Option>
                                {DIVISIONS.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                            </Select>
                        </Col>
                        <Col md={5}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.sharing}</span>
                            </div>
                            <Select className="InputSizeOfThird" style={{ width: 260 }} mode='multiple' value={this.state.sharing} onChange={(e) => this.onChangeSharing(e)}>
                                <Option value={'all'}>{strings.all}</Option>    
                                <Option value={"Y"}>{strings.yes}</Option>
                                <Option value={"N"}>{strings.no}</Option>
                            </Select>
                        </Col>
                        <Col md={5}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.type}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' style={{ width: 260 }} value={this.state.types} onChange={(e) => this.onChangeType(e)}>
                                <Option value={'all'}>{strings.all}</Option>
                                {TYPES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                            </Select>
                        </Col>
                        <Col md={5}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.class}</span>
                            </div>
                            <Select className="InputSizeOfThird" mode='multiple' style={{ width: 260 }} value={this.state.classes} onChange={(e) => this.onChangeClass(e)}>
                                <Option value={'all'}>{strings.all}</Option>
                                {SIZES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                            </Select>
                        </Col>
                    </Row>
                    <Button type="primary" size='large' style={{ marginRight: 17, marginTop: 10 }} loading={this.state.loading} onClick={this.searchData}>
                        {strings.search}
                    </Button>
                    <Button size='large' style={{ marginRight: 20, marginTop: 10 }} onClick={this.clearScrren}>
                        {strings.clear}
                    </Button>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Spin spinning={this.state.loading}>                    
                        {this.state.graphdata.length > 0 && <Row>
                            <Col span={2}></Col>
                            <Col span={20} style={{ padding: 10, background: 'rgb(245, 245, 245)' }}>
                                <Tabs activeKey={this.state.selectedTab} onChange={(e)=>this.onTabChange(e)} destroyInactiveTabPane={true}>
                                    {this.state.graphdata.map((r,i) => (
                                        <TabPane tab={r.nameEN} key={i} >
                                            <div style={{ padding: 10, background: 'white',height:350}}>
                                                <Row>
                                                    {HOURS.map((h,k)=><Col key={k} span={1} className={k === 0?'dateCol1':'headCol'} style={{ border:'1px solid gray', borderRightWidth:k+1 === HOURS.length?1:0, textAlign:'center' }}>{h}</Col>)}
                                                </Row>
                                                <div style={{overflowY:"auto",height:300}}>
                                                    {this.state.resourceData.map((r,i)=><Row key={i} >
                                                        <Col span={2} className='dateCol' style={{ border:'1px solid gray', borderTopWidth:0, borderRightWidth:0, textAlign:'center'}}>{r.date}</Col>
                                                        <Col span={22} style={{ textAlign:'center'}}>
                                                            <Row>
                                                                {r.data.map((d,j)=><Col key={j} span={1} onClick={()=>d.occupied != null ? this.onChangeValue(i,j):{}} className='dataCol' style={{cursor:'pointer', backgroundColor:this.getColor(d), border:'1px solid gray', borderTopWidth:0, borderRightWidth:j+1 === r.data.length?1:0}}>
                                                                    {d.clientOverridden !== null ? d.clientOverridden : d.occupied != null ? d.occupied : '--'}
                                                                </Col>)}
                                                            </Row>                                                
                                                        </Col>
                                                    </Row>)}
                                                </div>                                                                                       
                                            </div>                                        
                                        </TabPane>
                                    ))}
                                </Tabs>
                            </Col>
                            <Col span={2}></Col>
                        </Row>}                    
                        {this.state.graphdata.length === 0 && <Empty/>}
                    </Spin>
                    {this.state.graphdata.length>0 && <Row style={{marginTop:5}}>
                        <Col span={24}>                            
                            <Button style={{marginLeft: 10, background: '#49dc49', color: 'white', borderColor: '#49dc49',float:'right', marginRight:20}} disabled={this.state.changedData.length == 0} type="default" onClick={() => this.saveData()}>{strings.save}</Button>
                            <Button style={{float:'right'}} onClick={() => this.resetData()}>{strings.reset}</Button>
                        </Col>
                    </Row>}
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
            </div>
        );
    }
}
export default AnalystData;

