import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { DatePicker, Checkbox, Input, Row, Col, Calendar, Button, Select, Modal, message, Switch, Form } from 'antd';
import TableDragSelect from "react-table-drag-select";
import "react-table-drag-select/style.css";
import './App.css';
import './drag.css';
import moment from 'moment';
import TimelineCarparking from './TimelineCarparking';

import { ApiHeader, strings, apiurl, RESOURCE_TYPES } from './data';
const timelines = ['8AM', '8:15AM', '8:30AM', '8:45AM',
  '9AM', '9:15AM', '9:30AM', '9:45AM', '10AM', '10:15AM', '10:30AM', '10:45AM', '11AM', '11:15AM', '11:30AM', '11:45AM',
  '12PM', '12:15PM', '12:30PM', '12:45PM', '1PM', '1:15PM', '1:30PM', '1:45PM', '2PM', '2:15PM', '2:30PM', '2:45PM',
  '3PM', '3:15PM', '3:30PM', '3:45PM', '4PM', '4:15PM', '4:30PM', '4:45PM', '5PM', '5:15PM', '5:30PM', '5:45PM',
  '6PM', '6:15PM', '6:30PM', '6:45PM', '7PM', '7:15PM', '7:30PM', '7:45PM', '8PM', '8:15PM', '8:30PM', '8:45PM',
  '9PM'];

const timelines1 = ['8AM', '8:15AM', '8:30AM', '8:45AM',
  '9AM', '9:15AM', '9:30AM', '9:45AM', '10AM', '10:15AM', '10:30AM', '10:45AM', '11AM', '11:15AM', '11:30AM', '11:45AM',
  '12PM', '12:15PM', '12:30PM', '12:45PM', '1PM', '1:15PM', '1:30PM', '1:45PM', '2PM', '2:15PM', '2:30PM', '2:45PM',
  '3PM', '3:15PM', '3:30PM', '3:45PM', '4PM', '4:15PM', '4:30PM', '4:45PM', '5PM', '5:15PM', '5:30PM', '5:45PM',
  '6PM', '6:15PM', '6:30PM', '6:45PM', '7PM', '7:15PM', '7:30PM', '7:45PM', '8PM', '8:15PM', '8:30PM', '8:45PM',
  '9PM'];

  const timelines2 = ['08:00','08:15','08:30', '08:45',
  '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
  '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
  '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45',
  '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
  '21:00'];
  
const locale = 'ZH';
const Option = Select.Option;
const Search = Input.Search;

class Carpark extends Component {
  constructor(props) {
    super(props);
    ApiHeader.userId = sessionStorage.getItem("userId");
    this.state = {
      userId: ApiHeader.userId,
      resourceId: 1,
      startTime: 0,
      endTime: 0,
      dateValue: this.getCurrentDate(new Date()),
      timeValue: null,
      contactPhone1: "",
      contactPerson1: "",
      salutation1:"",
      salutation2:"",
      contactPhone2: "",
      contactPerson2: "",
      reason: "",
      carnumber: "",
      remark: "",
      approverId: 0,
      approverName: '',
      resourceType: RESOURCE_TYPES.VISITOR_CARPARK,
      division: '',
      staffType:1,
      resources: [],
      equipment: [],
      loading: false,
      selectedTime: '',
      systemData: null,
      isvisitor: true,
      approvers: [],
      showmodel: false,
      timeerr: false,
      cp1err: false,
      cph1err: false,
      reasonerr: false,
      divisioneeerr:false,
      carnumerr: false,
      triggermybooking: false,
      showalertmodel: false,
      alertmessage: "",
      issearching: false,
      searchstr: '',
      tbc: false,
      fp: true,
      value: moment(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`),
      d1: null, d2: null, d3: null, d4: null, d5: null, d6: null, d7: null,
      cells: [
        [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false],
      ],
      cellstemp: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false],

      cells1: [
        [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false],
      ],
      cellstemp1: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false],
      iselectric: false,
      visitorcompany: '',
      visitorname: '',
      purpose: 'meeting',
      visitorNameerr: false,
      visitorcompanyerr: false,
      isAgree:false,
      agreeerr:false,
      isofficehourmsg:false,
      isReminder:true,
      trs:[],
      mechanized:'V',
    };
  }

  componentDidMount() {
    sessionStorage.setItem('tabNumber', 2);
    var d = sessionStorage.getItem('systemdata');
    var sd = JSON.parse(d);
    //console.log(sd.mechanized)
    var cars = sd.car;
    this.getDateDetails({ _d: new Date() })
    for (var r = 0; r < cars.length; r++) {
      if (cars[r].isDefault == 1) {
        this.setState({ carnumber: cars[r].carNumber });
        break;
      }
    }
    var approver = sd.approver;
    for (var r = 0; r < approver.length; r++) {
      if (approver[r].isDefault == 1) {
        this.setState({ approverName: approver[r].approverName, approverId: approver[r].approverId,});
        break;
      }
    }
    for (var r = 0; r < cars.length; r++) {
      if (cars[r].isDefault == 1) {
        this.setState({ carnumber: cars[r].carNumber });
        break;
      }
    }
    this.setState({
      resources: sd.resource,mechanized:sd.mechanized, equipment: sd.equipment, systemData: sd, contactPhone1: sd.profile.contactPhone1 == null ? "" : sd.profile.contactPhone1,isReminder:sd.profile.isReminder==='Y'?true:false,
      contactPerson1: sd.profile.contactPerson1 == null ? "" : sd.profile.contactPerson1, contactPhone2: sd.profile.contactPhone2 == null ? "" : sd.profile.contactPhone2,
      contactPerson2: sd.profile.contactPerson2 == null ? "" : sd.profile.contactPerson2, division:sd.profile.division === null?'':sd.profile.division,
      salutation1:sd.profile.salutation1 == null ? "" : sd.profile.salutation1, 
      salutation2:sd.profile.salutation2 == null ? "" : sd.profile.salutation2, 
    });
    this.getBookingForDay(moment(this.state.dateValue).valueOf());
  }


  componentWillUnmount() {

  }

  handleTimelineChange = (cells) => {

   
    var times = [];
    var times1 = [];
    var index = 0;
    if (this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK) {
      var celltemp1 = this.state.cellstemp1;
      for (var i = 0; i < celltemp1.length; i++) {
        if (celltemp1[i] == true && cells[0][i] == true) {
          cells[0][i] = false;
        }
      }
      for (var i = 0; i < cells[0].length; i++) {
        if (cells[0][i] == true) {
          if (times.length == 0) {
            index = i;
            times.push(timelines1[i]);
            times1.push((i * 900000) + 28800000);
          } else {
            times1.push(((i + 1) * 900000) + 28800000);
            times.push(timelines1[i + 1]);
          }
        }
      }
      if (times.length == 1) {
        times.push(timelines1[index + 1]);
        times1.push(((index + 1) * 900000) + 28800000);
      }
      this.setState({ cells1: cells, cellstemp1: cells[0] });
    }else{
      var celltemp = this.state.cellstemp;
      for (var i = 0; i < celltemp.length; i++) {
        if (celltemp[i] == true && cells[0][i] == true) {
          cells[0][i] = false;
        }
      }
      for (var i = 0; i < cells[0].length; i++) {
        if (cells[0][i] == true) {
          if (times.length == 0) {
            index = i;
            times.push(timelines[i]);
            times1.push((i * 900000) + 28800000);
          } else {
            times1.push(((i + 1) * 900000) + 28800000);
            times.push(timelines[i + 1]);
          }
        }
      }
      if (times.length == 1) {
        times.push(timelines[index + 1]);
        times1.push(((index + 1) * 900000) + 28800000);
      }
      this.setState({ cells: cells, cellstemp: cells[0] });
    }
    


    var datestr = this.state.dateValue + ' 00:00:00';
    var ts = moment(datestr, "YYYY-MM-DD HH:mm:SS").valueOf();
    if (times[0] != undefined && times[times.length - 1] != undefined){
      var et = parseInt(ts + times1[times1.length - 1]);
      var st = parseInt(ts + times1[0]);
      
      this.setState({ 
        selectedTime: times[0] + " - " + times[times.length - 1],
        startTime: st, endTime: et, 
        isofficehourmsg: et>parseInt(ts+64800000) || st<parseInt(ts+30600000)?true:false
      });
    }      
    else{
      this.setState({ selectedTime: '', startTime: 0, endTime: 0 });
    }
      
  }

  getCurrentDate = (d) => {
    return [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');
  }

  onIsReminderChange=(e)=>{
    this.setState({isReminder:e.target.checked});
  }

  addBooking = () => {
    this.setState({ loading: true, timeerr: false,
      cp1err: false,
      cph1err: false,
      reasonerr: false,
      divisioneeerr:false,
      carnumerr: false,    
      visitorNameerr: false,
      visitorcompanyerr: false,
      agreeerr:false, })
    var rdata = {};
    var isvalid = true;
    rdata.userId = this.state.userId;
    rdata.originalUserId = this.state.userId;
    rdata.resourceId = this.state.resourceId;
    rdata.isReminder = this.state.isReminder?'Y':'N';
    if (this.state.startTime == 0 || this.state.endTime == 0) {
      this.setState({ timeerr: true });
      isvalid = false;
    } else {
      if (this.state.startTime <= new Date().getTime()) {
        this.setState({ alertmessage: strings.timeslorerr1, showalertmodel: true, loading: false })
        return;
      }
      if (this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK) {
        var newdate = new Date();
        newdate.setHours(23);
        newdate.setMinutes(59);
        newdate.setSeconds(59);
        newdate.setMilliseconds(999);
        if (this.state.startTime > newdate.getTime() + (1000 * 60 * 60 * 24 * 14)) {
          this.setState({ alertmessage: strings.timeslorerr3, showalertmodel: true, loading: false })
          return;
        }/* else if(moment(this.state.startTime).weekday() === 6 || moment(this.state.startTime).weekday() === 0){
          this.setState({ alertmessage: strings.timeslorerr5, showalertmodel: true, loading: false })
          return;
        } */
      } else {
        var newdate = new Date();
        newdate.setHours(23);
        newdate.setMinutes(59);
        newdate.setSeconds(59);
        newdate.setMilliseconds(999);
        newdate.setMonth(newdate.getMonth() + 3);
        if (this.state.startTime > newdate.getTime()) {
          this.setState({ alertmessage: strings.timeslorerr4, showalertmodel: true, loading: false })
          return;
        }
      }

      rdata.startTime = this.state.startTime;
      rdata.endTime = this.state.endTime;
    }

    if (this.state.contactPhone1 == "") {
      this.setState({ cph1err: true });
      isvalid = false;
    } else {

      if (this.state.contactPhone1.length <= 45) {
        rdata.contactPhone = this.state.contactPhone1;
      } else {
        message.warning(strings.contactphonemaxlength)
        isvalid = false;
      }
    }

    if (this.state.contactPerson1 == "") {
      this.setState({ cp1err: true });
      isvalid = false;
    } else {

      if (this.state.contactPerson1.length <= 100) {
        rdata.contactPerson = this.state.contactPerson1;
      } else {
        message.warning(strings.contactpersonmaxlength)
        isvalid = false;
      }
    }

    if (this.state.contactPhone2.length <= 45) {
      rdata.contactPhone1 = this.state.contactPhone2;
    } else {
      message.warning(strings.contactphonemaxlength)
      isvalid = false;
    }

    if (this.state.contactPerson2.length <= 100) {
      rdata.contactPerson1 = this.state.contactPerson2;
    } else {
      message.warning(strings.contactpersonmaxlength)
      isvalid = false;
    }

    if (this.state.remark.length <= 500) {
      rdata.remark = this.state.remark;
    } else {
      message.warning(strings.remarkmaxlength)
      isvalid = false;
    }


    rdata.resourceType = this.state.resourceType;
    if (this.state.reason == "") {
      this.setState({ reasonerr: true });
      isvalid = false;
    } else {
      if (this.state.reason.length <= 100) {
        rdata.reason = this.state.reason;
      } else {
        message.warning(strings.reasonmaxlength)
        isvalid = false;
      }
    }

    if (this.state.approverId == 0) {
      this.setState({ approvererr: true });
      isvalid = false;
    } else {
      rdata.approverId = this.state.approverId;
    }

    if (this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK) {
      //- first letter cannot be 0
      //- cannot contain letter I, O, Q
      //- when user input small letter, change it to capital lette

      if (this.state.carnumber == "") {
        this.setState({ carnumerr: true });
        isvalid = false;
      } else {
        if (this.state.carnumber.length <= 100) {
          if (/[^a-zA-Z0-9]/.test(this.state.carnumber) === false && this.state.carnumber[0] !== '0' && !this.state.carnumber.includes('I') && !this.state.carnumber.includes('O') && !this.state.carnumber.includes('Q')) {
            rdata.carNumber = this.state.carnumber;
          } else {
            message.warning(strings.carnumbernotvalid)
            isvalid = false;
          }
        } else {
          message.warning(strings.carnumbermaxlength)
          isvalid = false;
        }
      }
      rdata.isMechanized = this.state.mechanized==='O'?'Y':'N';
      rdata.staffType = this.state.staffType;
    } else {
      if (this.state.tbc == true) {
        rdata.carNumber = 'tobeconfirmed';
      } else {
        if (this.state.carnumber.length <= 100) {
          if (/[^a-zA-Z0-9]/.test(this.state.carnumber) === false && this.state.carnumber[0] !== '0' && !this.state.carnumber.includes('I') && !this.state.carnumber.includes('O') && !this.state.carnumber.includes('Q')) {
            rdata.carNumber = this.state.carnumber;
          } else {
            message.warning(strings.carnumbernotvalid)
            isvalid = false;
          }
        } else {
          message.warning(strings.carnumbermaxlength)
          isvalid = false;
        }
        
      }

      rdata.isElectric = this.state.iselectric ? 'yes' : 'no';
      if (this.state.visitorname == "") {
        this.setState({ visitorNameerr: true });
        isvalid = false;
      } else {
        rdata.visitorName = this.state.visitorname;
      }

      if (this.state.visitorcompany == "") {
        this.setState({ visitorcompanyerr: true });
        isvalid = false;
      } else {
        rdata.visitorCompany = this.state.visitorcompany;
      }
      rdata.purpose = this.state.purpose;
      rdata.isMechanized = this.state.mechanized==='V'?'Y':'N';
    }
    if(this.state.division === ''){
      this.setState({ divisioneeerr: true });
      isvalid = false;
    }else{
      rdata.division = this.state.division;
    }
    
    rdata.salutation = this.state.salutation1;
    rdata.salutation1 = this.state.salutation2;
    

    /* if(this.state.isMechanized === 'Y'){
      if(!this.state.isAgree){
        this.setState({ agreeerr: true });
        isvalid = false;
      }else{
        this.setState({ agreeerr: false });
      }
    } */

    if (isvalid == false) {
      this.setState({ loading: false })
      return;
    }

    fetch(apiurl + 'booking/create', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          this.setState({ alertmessage: strings.submittedsuccess, showalertmodel: true,triggermybooking:true })          
          this.clearScreen();
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => console.warn(e))
  }

  clearScreen = () => {
    this.setState({
      startTime: 0,
      endTime: 0,
      timeValue: null,
      contactPhone2: "",
      contactPerson2: "",
      reason: "",
      carnumber: "",
      approverId: 0,
      showmodel: false,
      iselectric: false,
      timeerr: false,
      cp1err: false,
      cph1err: false,
      reasonerr: false,
      divisioneeerr:false,
      carnumerr: false,
      visitorcompany: '',
      visitorname: '',
      purpose: 'meeting',
      visitorNameerr: false,
      visitorcompanyerr: false,
      tbc: false,
      approvers: [],
      approverName: '',
      remark: '',
      division:this.state.systemData.profile.division === null?'':this.state.systemData.profile.division,
      staffType:1,
      isAgree:false,
      agreeerr:false,
      contactPhone1: this.state.systemData.profile.contactPhone1 == null ? "" : this.state.systemData.profile.contactPhone1,
      contactPerson1: this.state.systemData.profile.contactPerson1 == null ? "" : this.state.systemData.profile.contactPerson1,
      contactPhone2: this.state.systemData.profile.contactPhone2 == null ? "" : this.state.systemData.profile.contactPhone2,
      contactPerson2: this.state.systemData.profile.contactPerson2 == null ? "" : this.state.systemData.profile.contactPerson2,
      salutation1:this.state.systemData.profile.salutation1 == null ? "" : this.state.systemData.profile.salutation1,
      salutation2:this.state.systemData.profile.salutation2 == null ? "" : this.state.systemData.profile.salutation2,
      isReminder: this.state.systemData.profile.isReminder==='Y'?true:false,
    });
    var cars = this.state.systemData.car;
    for (var r = 0; r < cars.length; r++) {
      if (cars[r].isDefault == 1) {
        this.setState({ carnumber: cars[r].carNumber });
        break;
      }
    }
    var approver = this.state.systemData.approver;
    for (var r = 0; r < approver.length; r++) {
      if (approver[r].isDefault == 1) {
        this.setState({ approverName: approver[r].approverName, approverId: approver[r].approverId });
        break;
      }
    }
    if (this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK) {
      this.handleTimelineChange(this.state.cells1);
    }else{
      this.handleTimelineChange(this.state.cells);
    }
    
  }

  getDateDetails = (details) => {
    this.setState({ details: details, value: moment(`${new Date(details._d).getFullYear()}-${new Date(details._d).getMonth() + 1}-${new Date(details._d).getDate()}`), dateValue: this.getCurrentDate(new Date(details._d)) });
    if (this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK) {
      this.handleTimelineChange(this.state.cells1);
    }else{
      this.handleTimelineChange(this.state.cells);
    }
  }

  onSelectedDate = (year, month, date) => {
    this.setState({ details: { _d: new Date(`${year}-${month}-${date}`) }, value: moment(`${year}-${month}-${date}`) })
  }

  goBack = () => {
    this.setState({ fp: true, resourceType: RESOURCE_TYPES.VISITOR_CARPARK});
    this.getDateDetails({ _d: this.state.datedetails })
  }

  onSelect = (value) => {

    this.setState({ dateValue: value.format('YYYY-MM-DD') });
    if (this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK) {
      var cells = [[false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false]];
      this.handleTimelineChange(cells);
    }else{
      var cells = [[false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false]];
      this.handleTimelineChange(cells);
    }
    this.getBookingForDay(value.valueOf());
  }

  handleTypeChange = (val) => {
    if (val === RESOURCE_TYPES.OPERATIONAL_CARPARK) {
      this.setState({ resourceType: val, isvisitor: false, tbc: false })
    } else {
      this.setState({ resourceType: val, isvisitor: true })
    }

  }

  searchapprover = () => {
    this.setState({ showmodel: true })
  }

  handleOk = () => {
    this.setState({ showmodel: false })
  }


  handleselectChange = (val) => {
    if (val != '0')
      this.setState({ approverId: parseInt(val.split('-')[0]), approverName: val.split('-')[1] })
  }


  handleSearch = (e) => {
    if (!this.state.issearching && this.state.searchstr.length > 1) {
      var val = this.state.searchstr;
      this.setState({ issearching: true, });
      var rdata = {};
      rdata.name = val;
      fetch(apiurl + 'user/search', {
        headers: ApiHeader,
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(rdata),
      }).then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
        .then(data => {
          if (data.code == "1") {
            var users = data.data.user;
            this.setState({ approvers: users, issearching: false });
          } else {
            this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, issearching: false })
          }
          this.setState({ issearching: false })
        })
        .catch(e => { console.warn(e); this.setState({ issearching: false }) })
    } else {
      this.setState({ alertmessage: strings.enter3char, showalertmodel: true })
    }
  }

  ontbcchange = (e) => {
    this.setState({ tbc: e.target.checked });
    if (e.target.checked == true) {
      this.setState({ carnumber: "" });
    }
  }

  findErrorMessage = (msg) => {
    return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
  }

  rendertd = () => {
    var res = [];    
    for (var i = 0; i < 52; i++) {
      res.push(<td key={i} className='dragselecttimeline' ></td>);
    }    
    return res;
  }

  rendertd1 = () => {
    var res = [];
    for (var i = 0; i < 52; i++) {
      res.push(<td key={i} className='dragselecttimeline' ></td>);      
    }
    return res;
  }


  tdclick = (d, type) => {
    var d1 = new Date();
    d1.setHours(0);
    d1.setMinutes(0);
    d1.setSeconds(0);
    d1.setMilliseconds(0);
    if (d1 <= d) {
      var isvaliddate = false;
      if (type == '1') {
        var d2 = new Date();
        d2.setMonth(d2.getMonth() + 6);

        if (d2 > d) {
          isvaliddate = true;
          this.handleTypeChange(RESOURCE_TYPES.VISITOR_CARPARK);
        }
      } else if (type == '2') {
        var d2 = new Date();
        d2.setDate(d2.getDate() + 14);
        if (d2 > d) {
          isvaliddate = true;
          this.handleTypeChange(RESOURCE_TYPES.OPERATIONAL_CARPARK);
        }
      }
      if (isvaliddate)
        this.setState({ fp: false, datedetails: d, dateValue: this.getCurrentDate(d) });
    }
    this.getBookingForDay(moment(this.getCurrentDate(d)).valueOf());
  }

  disabledDates=(d)=>{
    if(this.state.resourceType===RESOURCE_TYPES.VISITOR_CARPARK){
      return d.isAfter(moment().add(3,'months'));
    }else{
      return d.isAfter(moment().add(2,'weeks'));
    }    
  }

  rendertd2 = (tds) => {
    var rds = [];
    for (var i = 0; i < tds.length; i++) {
      var d =  tds[i];
      rds.push(<td key={i} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px',textAlign: 'center',backgroundColor: d.isBooked?'#dedede':'#fff' }}></td>);
    }
    return rds;
  }

  rendertd3 = (tds) => {
    var res = [];   
    for (var i = 0; i < tds.length; i++) {
      var d = tds[i];
      res.push(<td key={i} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px',textAlign: 'center',backgroundColor: d.isBooked?'#dedede':'#fff' }}></td>);
    }    
    return res;
  }

  getBookingForDay = (d) => {
    var rdata = {};
    rdata.date =d;
    fetch(apiurl + 'resource/currentvisitorparking', {
        headers: ApiHeader,
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(rdata),
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong ...');
        }
    })
    .then(data => {
        if (data.code == "1") {
            var vbs = data.data.vbs;
            var tbs = data.data.tbs;
            var specificcurrent = data.data.active1;
            var defaultcurrent = data.data.active2;
            var vtcount = 0, otcount = 0; 
            if(specificcurrent){
              vtcount = specificcurrent.visitorQuota;
              otcount = specificcurrent.operationalQuota;
            }else{
              vtcount = defaultcurrent.visitorQuota;
              otcount = defaultcurrent.operationalQuota;
            }
            var trs = [];
            var tds = [], tds1 = [];
            var datestr = moment(d).format('YYYY-MM-DD') + ' 00:00:00';
            var ts = moment(datestr, "YYYY-MM-DD HH:mm:SS").valueOf();
            ts = (ts / 1000) + 28800;
            for (var i = 0; i < timelines.length; i++) {
                if (i + 1 === timelines.length) {
                    break;
                }

                var vcount = 0, ocount = 0;
                var st = ts + (i * 900);
                var et = ts + ((i + 1) * 900);

                for (var b = 0; b < vbs.length; b++) {
                    var sd = vbs[b].startTime / 1000;
                    var ed = vbs[b].groupEndTime ? (vbs[b].groupEndTime/1000) : (vbs[b].endTime/1000);
                    if (sd < et && ed > st) {
                        vcount = vcount + 1;
                    }
                }
                var datestr = this.state.dateValue+' '+timelines2[i]
                var cdate = moment(datestr,'YYYY-MM-DD HH:mm');
                if(cdate.valueOf()<=moment().valueOf()){
                  tds.push({ id: i+"-1", isBooked: true });
                }else{
                  tds.push({ id: i+"-1", isBooked: vcount>=vtcount?true:false });
                }
                

                for (var b = 0; b < tbs.length; b++) {
                    var sd = tbs[b].startTime / 1000;
                    var ed = tbs[b].groupEndTime ? (tbs[b].groupEndTime/1000) : (tbs[b].endTime/1000);
                    if (sd < et && ed > st) {
                        ocount = ocount + 1;
                    }
                }
                if(cdate.valueOf()<=moment().valueOf()){
                  tds1.push({ id: i+"-2", isBooked: true });
                }else{
                  tds1.push({ id: i+"-2", isBooked: ocount>=otcount?true:false });
                }
                
            }
            trs.push({ id: 1, tds: tds });
            trs.push({ id: 2, tds: tds1 });
            this.setState({ trs: trs})
        } else {
            this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
    })
    .catch(e => {
        console.warn(e);
        this.setState({ loading: false });
    })
  } 


  render() {
    return (

      <div className="Tab1Div">
        <Row>
          <Col span={4} className='bookingcalanderdiv' style={{marginTop:this.state.fp?73:0}}>
            <div style={{ backgroundColor: '#fff', borderRadius: 4 }}>
              <Calendar fullscreen={false} onSelect={this.onSelect} disabledDate={this.disabledDates} value={moment(this.state.dateValue)} onChange={(_date) => this.getDateDetails(_date)} />
            </div>
          </Col>
          <Col span={20} hidden={!this.state.fp} className='bookinginfodiv'>
            <TimelineCarparking tdclick={this.tdclick} changeDay={this.state.fp} getDateDetails={this.getDateDetails} ds={{ d1: this.state.d1, d2: this.state.d2, d3: this.state.d3, d4: this.state.d4, d5: this.state.d5, d6: this.state.d6, d7: this.state.d7, }} onSelectedDate={this.onSelectedDate} details={this.state.details} />
          </Col>
          <Col span={20} hidden={this.state.fp} className='bookinginfodiv1'>

            <div className="SecondBox" style={{ marginTop: 0 }}>
              <span className="TitleOfTheBox">{strings.bookacarpark}</span>
              <div>
                <Row xs={24} style={{ marginTop: 7 }}>
                  <Col md={4} style={{ marginTop: 10 }}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className="DateText">{strings.date}</span>
                  </Col>

                  <Col md={6} style={{ marginTop: 10 }}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.dateValue} disabled />
                    </div>
                  </Col>
                  <Col md={4} style={{ marginTop: 10, }}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.timeerr ? "DateTextErr" : "DateText"}>{strings.time}</span>
                  </Col>
                  <Col md={6} style={{ marginTop: 10, }}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.selectedTime.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm')} disabled />
                    </div>
                  </Col>
                </Row>
                <div style={{ marginTop: 20, height: 100 }}>
                  <table><tbody>
                    <tr className='headertimeline'>
                      <td >8<span>am</span>-9<span>am</span></td>
                      <td >9<span>am</span>-10<span>am</span></td>
                      <td >10<span>am</span>-11<span>am</span></td>
                      <td >11<span>am</span>-12<span>pm</span></td>
                      <td >12<span>pm</span>-1<span>pm</span></td>
                      <td >1<span>pm</span>-2<span>pm</span></td>
                      <td >2<span>pm</span>-3<span>pm</span></td>
                      <td >3<span>pm</span>-4<span>pm</span></td>
                      <td >4<span>pm</span>-5<span>pm</span></td>
                      <td >5<span>pm</span>-6<span>pm</span></td>
                      <td >6<span>pm</span>-7<span>pm</span></td>
                      <td >7<span>pm</span>-8<span>pm</span></td>
                      <td >8<span>pm</span>-9<span>pm</span></td>
                    </tr></tbody>
                  </table>
                  {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK &&
                    <tr>
                      {this.rendertd2(this.state.trs.length>0?this.state.trs[1].tds:[])}
                    </tr>}
                  {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && <tr>
                      {this.rendertd3(this.state.trs.length>0?this.state.trs[0].tds:[])}
                    </tr>
                  }
                  {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK &&
                    <TableDragSelect value={this.state.cells1} onChange={this.handleTimelineChange}>
                      <tr>
                        {this.rendertd1()}
                      </tr>
                    </TableDragSelect>}
                    {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && 
                    <TableDragSelect value={this.state.cells} onChange={this.handleTimelineChange}>
                      <tr>
                        {this.rendertd()}
                      </tr>
                    </TableDragSelect>
                  }
                </div>
                <div><span className="LongText2" style={{marginLeft:0}}>{strings.pleasedragetc1}</span></div>
                {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && <div><span className="LongText2" style={{marginLeft:0, color:'red'}}>{strings.visitorstaffnotice}</span></div>}
                {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK && <div><span className="LongText2" style={{marginLeft:0, color:'red'}}>{strings.operationalstaffnotice}</span></div>}
                <Row xs={24} style={{ marginTop: 20 }}>
                  <Col md={4} >
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className="DateText">{strings.type}</span>
                  </Col>
                  <Col md={7} >
                    <div>
                      <Select size="large" className='bookingDatebox' value={this.state.resourceType} onChange={this.handleTypeChange}>
                        <Option value={RESOURCE_TYPES.VISITOR_CARPARK}>{strings.visitor}</Option>
                        <Option value={RESOURCE_TYPES.OPERATIONAL_CARPARK}>{strings.operational}</Option>
                      </Select>
                    </div>
                  </Col>
                  <Col md={4} >
                    <span className="DateText" style={{ marginLeft: 12 }}>{strings.parkingspace}</span>
                  </Col>
                  <Col md={6} >
                    <div>
                      <span className="DateText" style={{fontWeight:'normal'}}>{this.state.isvisitor ? this.state.mechanized === 'V' ?strings.mechanized : strings.nonmechanized:this.state.mechanized === 'O' ?strings.mechanized : strings.nonmechanized}</span>
                    </div>
                    {/* {this.state.isMechanized==='Y' && <span className="LongText2" style={{marginLeft:0}}>{strings.click} {' '}<a href='/srs_asset/user_manual.pdf' target="_blank" style={{color:'#ff7f27',textDecoration:'underline'}}>{strings.here}</a> {' '}{strings.todownloaduserguide}</span>} */}
                  </Col>
                </Row>
                {this.state.isvisitor && <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}> </span>
                    <span style={{ marginLeft: 12 }} className="DateText" >{strings.iselectric}</span>
                  </Col>
                  <Col md={7}>
                    <div>
                      <Switch checked={this.state.iselectric} onChange={(e) => this.setState({ iselectric: e })} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.visitorNameerr ? "DateTextErr" : "DateText"} >{strings.visitorname}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.visitorname} placeholder={strings.nameofvisitor} onChange={e => this.setState({ visitorname: e.target.value })} />
                    </div>
                  </Col>
                </Row>}

                {/* {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && this.state.iselectric && <div><span className="LongText2" style={{marginLeft:0, color:'red'}}>{strings.ecvehicalmsg}</span></div>}
 */}
                {this.state.isvisitor && <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.visitorcompanyerr ? "DateTextErr" : "DateText"} >{strings.visitorcompany}</span>
                  </Col>
                  <Col md={7}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.visitorcompany} placeholder={strings.nameofcompanydepartment} onChange={e => this.setState({ visitorcompany: e.target.value })} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className="DateText" >{strings.purpose}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Select size="large" className='bookingDatebox' value={this.state.purpose} onChange={e => this.setState({ purpose: e })}>
                        <Option value="meeting">{strings.meeting}</Option>
                        <Option value="training">{strings.training}</Option>
                        <Option value="briefing">{strings.briefing}</Option>
                        <Option value="recruitment_board">{strings.recruitmentboard}</Option>
                        <Option value="promotion_board">{strings.promotionboard}</Option>
                        <Option value="">{strings.others}</Option>
                      </Select>
                    </div>
                  </Col>
                </Row>}

                <Row xs={24} style={{marginTop: 20}} >
                  <Col md={4} >
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.reasonerr ? "DateTextErr" : "DateText"}>{strings.reason}</span>
                  </Col>
                  <Col md={20}>
                    <div>
                      <Input size="large" style={{ width: '74%' }} value={this.state.reason} placeholder={strings.pleasefill +' '+strings.reason} onChange={e => this.setState({ reason: e.target.value })} />
                    </div>
                  </Col>
                </Row>

                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4} hidden={!this.state.isvisitor} style={{ marginTop: 9, }}>
                    <span style={{ marginLeft: 12 }} className="DateText">{strings.carplatenumber}</span>
                  </Col>
                  <Col md={4} hidden={this.state.isvisitor}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.carnumerr ? "DateTextErr" : "DateText"}>{strings.carplatenumber}</span>
                  </Col>
                  <Col md={5}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.carnumber} disabled={this.state.tbc} placeholder={strings.pleasefill+' '+strings.carplatenumber} onChange={e => this.setState({ carnumber: e.target.value?e.target.value.toUpperCase():'' })} />
                    </div>
                  </Col>
                  <Col md={5} hidden={this.state.isvisitor == false} style={{ marginLeft: 10, marginTop: 8, color:'#405069' }}>
                    <div>
                      <Checkbox onChange={(e) => this.ontbcchange(e)} style={{ marginLeft: 5 }} checked={this.state.tbc}>{strings.tobeconfirmed}</Checkbox>
                    </div>
                  </Col>
                </Row>
                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.approvererr ? "DateTextErr" : "DateText"}>{strings.approver}</span>
                  </Col>
                  <Col md={5}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.approverName} placeholder={strings.pleasefill+' '+strings.approver} disabled />
                    </div>
                  </Col>
                  <Col md={2} style={{ marginTop: 5 }}>
                    <div className="Btnsubscribe">
                      <Button className="subscribe" type="danger" style={{marginLeft:this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK?0:15}} loading={this.state.loading4} onClick={this.searchapprover}>
                        {strings.search}
                      </Button>
                    </div>
                  </Col>
                  {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK && <Col md={4} >
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.divisioneeerr?"DateTextErr":"DateText"}>{strings.stafftype}</span>
                  </Col>}
                  {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK && <Col md={6}>
                    <div>
                      <Select size="large" className='bookingDatebox' value={this.state.staffType} placeholder={strings.pleasefill+' '+strings.stafftype} onChange={e => this.setState({ staffType: e })}>
                        <Option value={1}>{strings.stafftype1}</Option>
                        <Option value={2}>{strings.stafftype2}</Option>
                      </Select>
                    </div>
                  </Col>}
                </Row>
                
                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4} >
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.divisioneeerr?"DateTextErr":"DateText"}>{strings.division}</span>
                  </Col>
                  <Col md={7}>
                    <div>
                      <Select size="large" className='bookingDatebox' value={this.state.division} placeholder={strings.pleasefill+' '+strings.division} onChange={e => this.setState({ division: e })}>
                        <Option key={0} value={''}>{strings.pleasefill+' '+strings.division}</Option>
                        {strings.divisions.map((d) => <Option key={d} value={d}>{d}</Option>)}
                      </Select>
                    </div>
                  </Col>
                  <Col md={4} style={{marginTop:10}}>
                    <span style={{ marginLeft: 12 }} className="DateText">{strings.remark}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.remark} placeholder={strings.pleasefill+' '+strings.remark} onChange={e => this.setState({ remark: e.target.value })} />
                    </div>
                  </Col>
                </Row>
                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.cp1err ? "DateTextErr" : "DateText"}>{strings.contactperson1}</span>
                  </Col>
                  <Col md={7}>
                    <div>
                      <Select size="large" style={{ width: 80, marginRight:5 }} className="bookingselect" value={this.state.salutation1} onChange={e => this.setState({ salutation1: e })} >
                          <Option value="MR">{strings.MR}</Option>
                          <Option value="MS">{strings.MS}</Option>
                          <Option value="MISS">{strings.MISS}</Option>
                      </Select>
                      <Input size="large" className='bookingDatebox' value={this.state.contactPerson1} placeholder={strings.pleasefill+' '+strings.contactperson1} onChange={e => this.setState({ contactPerson1: e.target.value })} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.cph1err ? "DateTextErr" : "DateText"}>{strings.contactphone1}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.contactPhone1} placeholder={strings.pleasefill+' '+strings.contactphone1} onChange={e => this.setState({ contactPhone1: e.target.value })} />
                    </div>
                  </Col>
                </Row>

                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span className="DateText" style={{ marginLeft: 12 }}>{strings.contactperson2}</span>
                  </Col>
                  <Col md={7}>
                    <div>
                      <Select size="large" style={{ width: 80, marginRight:5 }} className="bookingselect" value={this.state.salutation2} onChange={e => this.setState({ salutation2: e })} >
                          <Option value="MR">{strings.MR}</Option>
                          <Option value="MS">{strings.MS}</Option>
                          <Option value="MISS">{strings.MISS}</Option>
                      </Select>
                      <Input size="large" className='bookingDatebox' value={this.state.contactPerson2} placeholder={strings.pleasefill+' '+strings.contactperson2} onChange={e => this.setState({ contactPerson2: e.target.value })} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <span className="DateText" style={{ marginLeft: 12 }}>{strings.contactphone2}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.contactPhone2} placeholder={strings.pleasefill+' '+strings.contactphone2} onChange={e => this.setState({ contactPhone2: e.target.value })} />
                    </div>
                  </Col>
                </Row>
                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span className="DateText" style={{ marginLeft: 12 }}>{strings.isreminder}</span>
                  </Col>
                  <Col md={1}>
                    <div>
                    <Checkbox onChange={(e) => this.onIsReminderChange(e)} style={{ marginLeft: 5 }} checked={this.state.isReminder}>{''}</Checkbox>
                    </div>
                  </Col>
                  <Col md={14}>
                    <span className="LongText2" style={{marginLeft:0}}>{strings.carparkremindermsg}</span>
                  </Col>
                </Row>

                {/* {this.state.isMechanized==='Y' && <div style={{ marginTop: 20}}>
                  <Checkbox onChange={(e)=>this.setState({isAgree:e.target.checked})} checked={this.state.isAgree} style={{fontSize:14,color:this.state.agreeerr?'#ff0000':'#405069'}}>{strings.termsandcondition} {' '}<a href='/srs_asset/disclaimer.pdf' target="_blank" style={{color:this.state.agreeerr?'#ff0000':'#405069',textDecoration:'underline'}}>{strings.termsandconditionlink}</a>{' '}{strings.termsandcondition1}</Checkbox>
                </div>} */}

                <div style={{ marginTop: 20, marginBottom: 30 }}>
                  <span>
                    <Button type="primary" size='large' style={{ marginRight: '8%' }} loading={this.state.loading} onClick={this.addBooking}>
                      {strings.book}
                    </Button>
                    <Button size='large' style={{ marginRight: 20 }} onClick={this.goBack}>
                      {strings.back}
                    </Button>
                    <Button size='large' style={{ marginRight: 20 }} onClick={this.clearScreen}>
                      {strings.clear}
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </Col>


        </Row>
        <Modal
          title={strings.searchapprover}
          visible={this.state.showmodel}
          onOk={this.handleOk}
          onCancel={() => this.setState({ showmodel: false })}
          footer={[

            <Button key="submit" className='subscribe1' type="danger" onClick={this.handleOk}>
              {strings.select}
            </Button>,
          ]}
        >
          <div style={{ paddingTop: 5 }}>
            <Row>
              <span style={{ color: '#ff7f27', fontSize: 14 }}>{strings.plsincludeslashinsearch}</span>
            </Row>
            <Row type="flex" style={{ marginTop: 15 }}>
              <Col span={6}>{strings.postname1}</Col>
              <Col span={18}>
                <Form>
                  <Row type="flex">
                    <Col span={17}>
                      <Input value={this.state.searchstr} placeholder={strings.enter3char} onChange={e => this.setState({ searchstr: e.target.value })} />
                    </Col>
                    <Col span={6}>
                      <Button type="danger" className='subscribe1' htmlType="submit" style={{ marginLeft: 15, marginTop: 0 }} disabled={this.state.issearching} loading={this.state.issearching} onClick={(e) => this.handleSearch(e)}>
                        {strings.search}
                      </Button>
                    </Col>
                  </Row>
                </Form>                
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: 15 }}>
              <Col span={6}>{strings.approver}</Col>
              <Col span={18}>
                <Select defaultValue="0" style={{ width: '100%' }} onChange={this.handleselectChange}>
                  <Option value="0">{strings.pleaseselect}</Option>
                  {this.state.approvers.map(d => <Option key={d.id + '-' + d.name}>{d.name} ({d.post})</Option>)}

                </Select>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          title={strings.alert}
          visible={this.state.showalertmodel}
          onCancel={() => this.setState({ showalertmodel: false })}
          footer={[
            <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggermybooking)this.props.gotomybooking(); }}>
              {strings.ok}
            </Button>,
          ]}
        >
          <span className="TitleOfTheBox">{strings.errormessage}</span><br />
          <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
        </Modal>
      </div>
    );

  }
}

export default Carpark;